import { Button, Container, Grid, InputBase, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from "react";
import { User } from "../../../models/user";
import { UserService } from '../../../services/UserService';
import { getDataUser } from '../../../storage/userStorage';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SweetAlert } from "../../../components/SweetAlert";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2e3192',
    color: 'white',
    fontSize: '1rem',
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.9rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const Users = () => {
    const navigation = useNavigate();
    const userService = UserService();
    const sweetAlert = SweetAlert();
    const [users, setUsers] = useState<User[]>([]);
    const [usersFilter, setUsersFilter] = useState<User[]>([]);

    const actionUser = (id?: string) => {
        if (id === undefined)
            navigation('/administracion/usuario/0');
        else
            navigation(`/administracion/usuario/${id}`);
    }

    const deleteUser = async (id: string, userFullName: string) => {
        let result = await sweetAlert.showAlertWithButton(`el usuario ${userFullName}`);
        if (result) {
            const response = await userService.deleteUser(id, getDataUser().token);
            if (!response.isSuccess) {
                sweetAlert.showAlert(response.message, response.isSuccess)
            }
            else {
                sweetAlert.showAlert('El usuario se elimino correctamente', response.isSuccess);
                setUsersFilter(users.filter(x => x.id !== id));
            }
        }
    }

    const searchUsers = (event: any) => {
        if (event.target.value !== '') {
            const newData = users.filter(function(item) {
                const itemNombre = item.nombre.toUpperCase();
                const itemApellido = item.apellido.toUpperCase();
                const itemUsuario = item.nombreUsuario.toUpperCase();
                const campo = `${itemNombre} ${itemApellido} ${itemUsuario}`;
                const textData = event.target.value.toUpperCase();
                return campo.indexOf(textData) > -1;
            });
            setUsersFilter(newData);
        } else {
            setUsersFilter(users);
        }
    }

    useEffect(() => {
        const getUsers = async () => {
            const data = await userService.getAllUsers(getDataUser().token);
            setUsers(data.data);
            setUsersFilter(data.data);
        }
        getUsers();
    }, [])

    return (
        <Container>
            <Typography variant="body2">Administracion/Usuarios</Typography>
            <Button variant='contained'
                    color='primary'
                    sx={{
                        marginLeft: '80%'
                    }}
                    onClick={() => actionUser()}>
                Crear usuario
            </Button>
            <Grid sx={{
                marginTop: '5%'
            }}>
                <Paper component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto', marginBottom: '2rem' }} >
                    <InputBase sx={{ ml: 1, flex: 1 }}
                        placeholder="Buscar por nombre, apellido o usuario"
                        name='inputSearch'
                        onChange={ (event) => searchUsers(event) }/>
                </Paper>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%', minHeight: '20%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Nombre</StyledTableCell>
                                <StyledTableCell>Apellido</StyledTableCell>
                                <StyledTableCell width='15%'>Usuario</StyledTableCell>
                                <StyledTableCell width='15%'>Perfil</StyledTableCell>
                                <StyledTableCell width='15%' align='right'></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usersFilter?.map((user) => (
                                <StyledTableRow key={user.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {user.nombre}
                                    </StyledTableCell>
                                    <StyledTableCell>{user.apellido}</StyledTableCell>
                                    <StyledTableCell>{user.nombreUsuario}</StyledTableCell>
                                    <StyledTableCell>{user.perfil}</StyledTableCell>
                                    <StyledTableCell>
                                        <Button onClick={() => actionUser(user.id)}>
                                            <ModeEditIcon />
                                        </Button>
                                        <Button onClick={() => deleteUser(user.id, `${user.nombre} ${user.apellido}`)}>
                                            <DeleteIcon />
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                            <Typography></Typography>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Container>
    )
}
