import { Button, Card, CardContent, Container, Grid, Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Tracking } from '../../../models/tracking';
import { TrackingService } from '../../../services/TrackingService';
import { getDataUser } from '../../../storage/userStorage';
import { SweetAlert } from '../../../components/SweetAlert';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2e3192',
      color: 'white',
      fontSize: '1rem',
      textAlign: 'center'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '0.9rem',
    },
  }));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const TrackingHistory = () => {
    const trackingService = TrackingService();
    const sweetAlert = SweetAlert();
    const [tracking, setTracking] = useState<Tracking[]>([]);
    const [dateFilter, setDateFilter] = useState({
        fechaDesde: new Date(),
        fechaHasta: new Date()
    });

    const exportExcel = () => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        let trackingExcel: any = [];
        tracking.map((item) => {
            let obj = {
                'Fecha Recepción': new Date(item.fechaCreacion).toLocaleDateString('en-GB'),
                'Guía': item.numeroControl,
                'Nro. de Bultos': 1,
                'KG': item.pesoFacturado,
                'Contenido': item.contenido,
                'Remitente': `${item.remitente.nombre} ${item.remitente.apellido}`,
                'Documento de Identidad Remitente': item.remitente.numeroDocumento,
                'Telefono Remitente': item.remitente.telefono,
                'Destinatario': `${item.destinatario.nombre} ${item.destinatario.apellido}`,
                'Documento de Identidad Destinatario': item.destinatario.numeroDocumento,
                'Telefono Destinatario': item.destinatario.telefono,
                'Destino': item.destinatario.ciudad,
                'Dirección': item.destinatario.direccion,
                'Identificación del Inmueble': item.destinatario.observacion,
                'Valor en Soles': new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.totalCobrarSoles),
                'Valor en USD': new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.totalCobrarDolares)
            };
            trackingExcel.push(obj);
        });
        const ws = XLSX.utils.json_to_sheet(trackingExcel);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'Consolidado Galivica Express' + fileExtension);
    }

    const changeDate = (newValue: any, fecha: string) => {
        setTracking([]);
        var date = new Date();
        date.setDate(newValue.$D);
        date.setMonth(newValue.$M)
        date.setFullYear(newValue.$y);
        switch (fecha) {
            case 'fechaDesde':
                setDateFilter({...dateFilter, fechaDesde: date});
                break;
            case 'fechaHasta':
                setDateFilter({...dateFilter, fechaHasta: date});
                break;
        }
    }

    const searchTracking = async () => {
        const response = await trackingService.getTrackingByRange(dateFilter.fechaDesde.toLocaleDateString('en-US').replaceAll('/', '-'), dateFilter.fechaHasta.toLocaleDateString('en-US').replaceAll('/', '-'), getDataUser().token);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess)
        } else {
            setTracking(response.data);
        }
    }
    
    return (
        <Container>
            <Typography variant="body2">Historial de envios</Typography>
            <Button variant='contained'
                    color='primary'
                    sx={{
                        marginLeft: '90%'
                    }}
                    onClick={() => exportExcel()}>
                Exportar
            </Button>
            <Grid sx={{
                marginTop: '2%'
            }}>
                <Card variant='outlined'
                    sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)', marginBottom: '2rem' }}>
                    <CardContent>
                        <Grid container
                            sx={{ marginTop: '1rem' }}>
                            <Grid item xs={12} sm={2}
                                sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                                Fecha desde
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <DatePicker slotProps={{ textField: { fullWidth: true } }}
                                    onChange={ (newValue) => changeDate(newValue, 'fechaDesde') }
                                    value={dayjs(dateFilter.fechaDesde)}
                                    format='DD/MM/YYYY' />
                            </Grid>
                            <Grid item xs={12} sm={2}
                                sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                                Fecha hasta
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <DatePicker slotProps={{ textField: { fullWidth: true } }}
                                    onChange={ (newValue) => changeDate(newValue, 'fechaHasta') }
                                    value={dayjs(dateFilter.fechaHasta)}
                                    format='DD/MM/YYYY' />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Button variant='contained'
                                    color='primary'
                                    onClick={() => searchTracking()}
                                    sx={{ paddingTop: '8%', paddingBottom: '8%', marginLeft: '5%', width: '95%' }}>
                                    Buscar
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%', minHeight: '20%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Numero Control</StyledTableCell>
                                <StyledTableCell>Medidas</StyledTableCell>
                                <StyledTableCell>Almacen Origen</StyledTableCell>
                                <StyledTableCell>Almacen Destino</StyledTableCell>
                                <StyledTableCell>Caja Numero</StyledTableCell>
                                <StyledTableCell>Peso Facturado</StyledTableCell>
                                <StyledTableCell>Total a Cobrar en Soles</StyledTableCell>
                                <StyledTableCell>Total a Cobrar en Dolares</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tracking?.map((t) => (
                                <StyledTableRow key={t.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {t.numeroControl}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>{t.medidas}</StyledTableCell>
                                    <StyledTableCell align='center'>{t.almacenOrigen}</StyledTableCell>
                                    <StyledTableCell align='center'>{t.almacenDestino}</StyledTableCell>
                                    <StyledTableCell align='center'>{t.cajaNumero}</StyledTableCell>
                                    <StyledTableCell align='center'>{t.pesoFacturado} {t.tipoPeso}</StyledTableCell>
                                    <StyledTableCell align='center'>{new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(t.totalCobrarSoles)}</StyledTableCell>
                                    <StyledTableCell align='center'>{new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(t.totalCobrarDolares)}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Container>
    )
}
