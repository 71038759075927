import { AppBar, Avatar, Box, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../models/user/User';
import { getDataUser, removeDataUser } from '../storage/userStorage';

export const Appbar = () => {
    const [user, setUser] = useState<User>();
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const navigation = useNavigate();

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logOut = () => {
        removeDataUser();
        navigation('/auth');
      };

    useEffect(() => {
        setUser(getDataUser());
    }, []);

    return (
        <AppBar sx={{
            float: 'left',
            display: 'block',
            width: '100%',
            minHeight: '5%',
            backgroundColor: 'primary.dark'
        }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 0, width: '50%', marginLeft: '90%' }}>
                        <Tooltip title="Open settings"
                            sx={{
                                width: '50%'
                            }}>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Usuario" src="../assets/images/usuario.png" />
                                <Typography variant='body1'
                                    sx={{
                                        marginLeft: '1%',
                                        color: 'white'
                                    }}>{user?.nombre} {user?.apellido}</Typography>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px', marginRight: '1%' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}>
                            <MenuItem key='configuracion' onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">Configuracion</Typography>
                            </MenuItem>
                            <MenuItem key='cerrarsesion' onClick={logOut}>
                                <Typography textAlign="center">Cerrar sesion</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
