import { Button, Card, CardContent, Container, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SweetAlert } from '../../../components/SweetAlert';
import { RateDay } from '../../../models/rateDay';
import { RateDayService } from '../../../services/RateDayService';
import { getDataUser } from '../../../storage/userStorage';

export const RateDays = () => {
    const rateDayService = RateDayService();
    const sweetAlert = SweetAlert();
    const [rateDay, setRateDay] = useState<RateDay>({} as RateDay);
    const [rate, setRate] = useState<number>(0);
    const [newRate, setNewRate] = useState<number>(0);
    const [hasError, setHasError] = useState({
        tasa: false
    });
    const [isValidation, setIsValidation] = useState(false);

    const changeInput = (event: any) => {
        const {name, value} = event.target;
        const regex = /^[0-9\,]*$/;
        if (!regex.test(value)) {
            sweetAlert.showAlert('El campo Tasa solo acepta valores numericos', false);
            return;
        }
        setNewRate(value);
    }

    const validateField = async () => {
        if (newRate === 0) {
            setHasError({
                ...hasError,
                tasa: rateDay.tasa === undefined
            });
            return true;
        }
        return false;
    }

    const actionRateDay = async () => {
        setIsValidation(true);
        if (await validateField()) {
            setIsValidation(false);
            return;
        }
        rateDay.tasa = parseFloat(newRate.toString().replace(',', '.'));
        const response = await rateDayService.updateRateDay(rateDay, getDataUser().token);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess)
            setIsValidation(false);
            setRateDay(response.data);
            setRate(response.data.tasa);
        }
        else {
            sweetAlert.showAlert('La tasa se actualizo correctamente', response.isSuccess);
            setIsValidation(false);
        }
    }

    useEffect(() => {
        const getRateDay = async () => {
            const data = await rateDayService.getRateDay(getDataUser().token);
            if (data.isSuccess) {
                setRateDay(data.data);
                setRate(data.data.tasa);
            }
        }
        getRateDay();
    }, [rateDay])
    

    return (
        <Container>
            <Typography variant="body2">Tasa del dia</Typography>
            <Grid sx={{
                marginTop: '2%'
            }}>
                <Card variant='outlined'
                    sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)', marginBottom: '2rem' }}>
                    <CardContent>
                        <Grid container
                            sx={{ marginBottom: '1rem' }}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant='h6' sx={{ marginTop: '1rem' }}>
                                    Tasa actual: <strong>${new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(rate)}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <TextField type='text'
                                    placeholder='Ingrese la nueva tasa'
                                    name='tasa'
                                    fullWidth
                                    required
                                    variant='outlined'
                                    value={newRate}
                                    onChange={ changeInput }
                                    disabled={ isValidation }
                                    error={ hasError.tasa }
                                    helperText={ hasError.tasa ? 'Debe ingresar una tasa' : ''} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button sx={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                                    onClick={ actionRateDay }
                                    disabled={ isValidation }
                                    variant='contained'
                                    color='primary'>
                                    Modificar Tasa
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Container>
    )
}
