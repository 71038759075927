import { Box, Button, Card, CardActions, CardContent, Container, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SweetAlert } from "../../../components/SweetAlert";
import { ServiceType } from "../../../models/serviceType";
import { ServiceTypeService } from "../../../services/ServiceTypeService";
import { getDataUser } from "../../../storage/userStorage";

export const ServiceTypeActions = () => {
    const navigation = useNavigate();
    const serviceTypeService = ServiceTypeService();
    const sweetAlert = SweetAlert();
    const [serviceType, setServiceType] = useState<ServiceType>({} as ServiceType);
    const {id} = useParams();
    const [hasError, setHasError] = useState({
        descripcion: false,
        valorSoles: false,
        valorDolares: false
    });
    const [isValidation, setIsValidation] = useState(false);

    const changeInput = (event: any) => {
        const {name, value} = event.target;
        const regex = /^[0-9\,]*$/;
        switch (name) {
            case 'valorSoles':
                if (!regex.test(value)) {
                    setServiceType({ ...serviceType, [name]: serviceType.valorSoles });
                    sweetAlert.showAlert('El campo Valor Soles solo acepta valores numericos', false);
                    return;
                }
                setServiceType({ ...serviceType, [name]: value });
                break;
            case 'valorDolares':
                if (!regex.test(value)) {
                    setServiceType({ ...serviceType, [name]: serviceType.valorDolares });
                    sweetAlert.showAlert('El campo Valor Dolares solo acepta valores numericos', false);
                    return;
                }
                setServiceType({ ...serviceType, [name]: value });
                break;
            default:
                setServiceType({ ...serviceType, [name]: value });
                break;
        }
    }

    const validateField = async () => {
        if (serviceType.descripcion === undefined || serviceType.valorSoles === undefined ||
            serviceType.valorDolares === undefined) {
            setHasError({
                descripcion: serviceType.descripcion === undefined,
                valorSoles: serviceType.valorSoles === undefined,
                valorDolares: serviceType.valorDolares === undefined
            });
            return true;
        }
        return false;
    }

    const actionServiceType = async () => {
        setIsValidation(true);
        if (await validateField()) {
            setIsValidation(false);
            return;
        }
        serviceType.valorSoles = parseFloat(serviceType.valorSoles.toString().replace(',', '.'));
        serviceType.valorDolares = parseFloat(serviceType.valorDolares.toString().replace(',', '.'));
        const response = id !== '0' ? await serviceTypeService.updateServiceType(serviceType, getDataUser().token)
                        : await serviceTypeService.createServiceType(serviceType, getDataUser().token);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess)
            setIsValidation(false);
        }
        else {
            sweetAlert.showAlert(id !== '0' ? 'El tipo de servicio se modifico correctamente' : 'El tipo de servicio se creo correctamente', response.isSuccess);
            navigation('/administracion/tipo-servicios');
            setIsValidation(false);
        }
    }

    useEffect(() => {
        const getServiceType = async (id: any) => {
            const data = await serviceTypeService.getServiceTypeById(id, getDataUser().token);
            setServiceType(data.data);
        }
        if (id !== '0') getServiceType(id);
    }, [])

    return (
        <Container>
            <Typography variant="body2">Administracion/{id !== '0' ? 'Modificar Tipo de Servicio' : 'Crear Tipo de Servicio'}</Typography>
            <Grid sx={{
                marginTop: '5%'
            }}>
                <Typography variant='h3'>Datos del tipo de servicio</Typography>
                <Divider />
                <Box p={3}>
                    <Box mt={1.625}>
                        <Card variant='outlined'
                            sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)' }}>
                            <CardContent>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField type='text'
                                            placeholder='Descripcion'
                                            name='descripcion'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={serviceType.descripcion}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.descripcion }
                                            helperText={ hasError.descripcion ? 'Debe ingresar una descripcion' : ''} />
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Valor Soles'
                                            name='valorSoles'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={serviceType.valorSoles}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.valorSoles }
                                            helperText={ hasError.valorSoles ? 'Debe ingresar un valor' : ''} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Valor Dolares'
                                            name='valorDolares'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={serviceType.valorDolares}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.valorDolares }
                                            helperText={ hasError.valorDolares ? 'Debe ingresar un valor' : ''} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button variant='contained'
                                        color='primary'
                                        sx={{
                                            marginLeft: '75%',
                                            width: '25%'
                                        }}
                                        disabled={ isValidation }
                                        onClick={ actionServiceType }>
                                    {id !== '0' ? 'Modificar Tipo Servicio' : 'Crear Tipo Servicio'}
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Box>
            </Grid>
        </Container>
    )
}
