import { CustomResponse } from '../models';
import { ExpensePurchaseControl, ExpensePurchaseControlRequest } from '../models/expensePurchaseControl';
import api from './ConfigService';

export const ExpensePurchaseControlService = () => {
    const getAllExpensePurchaseControl = async (token: string): Promise<CustomResponse<ExpensePurchaseControl[]>> => {
        let expensePurchaseControl: CustomResponse<ExpensePurchaseControl[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get('ControlGastoCompra', { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            expensePurchaseControl = response.data;
        }).catch(error => {
            expensePurchaseControl = error;
        });
        return expensePurchaseControl;
    }

    const getExpensePurchaseControlByDate = async (date: string, token: string): Promise<CustomResponse<ExpensePurchaseControl[]>> => {
        let expensePurchaseControl: CustomResponse<ExpensePurchaseControl[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get(`ControlGastoCompra/PorFecha/${date}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            expensePurchaseControl = response.data;
        }).catch(error => {
            expensePurchaseControl = error;
        });
        return expensePurchaseControl;
    }

    const getExpensePurchaseControlByRange = async (dateStart: string, dateEnd: string, token: string): Promise<CustomResponse<ExpensePurchaseControl[]>> => {
        let expensePurchaseControl: CustomResponse<ExpensePurchaseControl[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get(`ControlGastoCompra/PorRango/${dateStart}/${dateEnd}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            expensePurchaseControl = response.data;
        }).catch(error => {
            expensePurchaseControl = error;
        });
        return expensePurchaseControl;
    }

    const getExpensePurchaseControlById = async (id: string, token: string): Promise<CustomResponse<ExpensePurchaseControl>> => {
        let expensePurchaseControl: CustomResponse<ExpensePurchaseControl> = {} as CustomResponse<ExpensePurchaseControl>;
        await api.get(`ControlGastoCompra/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            expensePurchaseControl = response.data;
        }).catch(error => {
            expensePurchaseControl = error;
        });
        return expensePurchaseControl;
    }

    const createExpensePurchaseControl = async (expensePurchaseControlRequest: ExpensePurchaseControlRequest, token: string): Promise<CustomResponse<ExpensePurchaseControl>> => {
        let expensePurchaseControl: CustomResponse<ExpensePurchaseControl> = {} as CustomResponse<ExpensePurchaseControl>;
        await api.post('ControlGastoCompra', expensePurchaseControlRequest, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            expensePurchaseControl = response.data;
        }).catch(error => {
            expensePurchaseControl = error;
        });
        return expensePurchaseControl;
    }

    const updateExpensePurchaseControl = async (expensePurchaseControlRequest: ExpensePurchaseControl, token: string): Promise<CustomResponse<ExpensePurchaseControl>> => {
        let expensePurchaseControl: CustomResponse<ExpensePurchaseControl> = {} as CustomResponse<ExpensePurchaseControl>;
        await api.put('ControlGastoCompra', expensePurchaseControlRequest, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            expensePurchaseControl = response.data;
        }).catch(error => {
            expensePurchaseControl = error;
        });
        return expensePurchaseControl;
    }

    const deleteExpensePurchaseControl = async (id: string, token: string): Promise<CustomResponse<boolean>> => {
        let flat: CustomResponse<boolean> = {} as CustomResponse<boolean>;
        await api.delete(`ControlGastoCompra/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            flat = response.data;
        }).catch(error => {
            flat = error;
        });
        return flat;
    }

    return {
        getAllExpensePurchaseControl,
        getExpensePurchaseControlByDate,
        getExpensePurchaseControlByRange,
        getExpensePurchaseControlById,
        createExpensePurchaseControl,
        updateExpensePurchaseControl,
        deleteExpensePurchaseControl
    }
}