import { Card, CardContent, Container, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { SweetAlert } from "../../../components/SweetAlert";
import { ServiceType } from "../../../models/serviceType";
import { ServiceTypeService } from "../../../services/ServiceTypeService";
import { getDataUser } from "../../../storage/userStorage";

export const StepGeneral = ({data, setData, handleStep}: any) => {
    const serviceTypeService = ServiceTypeService();
    const [serviceType, setServiceType] = useState<ServiceType[]>([]);
    const [serviceSelected, setServiceSelected] = useState<ServiceType>({} as ServiceType);
    const [isValidation, setIsValidation] = useState(false);
    const [hasError, setHasError] = useState({
        ancho: false,
        alto: false,
        profundidad: false,
        almacenOrigen: false,
        almacenDestino: false,
        cajaNumero: false,
        pesoFacturado: false,
        tipoPeso: false,
        valorSoles: false,
        valorDolares: false,
        totalCobrarSoles: false,
        totalCobrarDolares: false
    });
    const sweetAlert = SweetAlert();

    const changeInput = (event: any) => {
        const {name, value, placeholder} = event.target;
        const regex = /^[0-9\,]*$/;
        switch (name) {
            case 'ancho':
            case 'alto':
            case 'profundidad':
            case 'cajaNumero':
                if (!regex.test(value)) {
                    sweetAlert.showAlert(`El campo ${placeholder} solo acepta valores numericos`, false);
                    return;
                }
                setData({...data, [name]: value});
                break;
            case 'efectivoSoles':
            case 'efectivoDolares':
            case 'transferenciaSoles':
            case 'transferenciaDolares':
            case 'yapeSoles':
            case 'yapeDolares':
            case 'contraentregaDolares':
                if (!regex.test(value)) {
                    sweetAlert.showAlert(`El campo ${placeholder} solo acepta valores numericos`, false);
                    return;
                }
                setData({...data, [name]: value});
                break;
            case 'pesoFacturado':
                debugger;
                setData({...data, [name]: value});
                if (data.tipoPeso !== undefined && data.tipoServicio !== undefined) {
                    if (data.tipoPeso === 'Gr') {
                        const valorFormat = parseFloat(value.toString().replace(',', '.'));
                        setData({
                            ...data,
                            totalCobrarSoles: valorFormat * (serviceSelected.valorSoles / 1000),
                            totalCobrarDolares: valorFormat * (serviceSelected.valorDolares / 1000)
                        });
                    } else {
                        const valorFormat = parseFloat(value.toString().replace(',', '.'));
                        setData({
                            ...data,
                            totalCobrarSoles: valorFormat * serviceSelected.valorSoles,
                            totalCobrarDolares: valorFormat * serviceSelected.valorDolares
                        });
                    }
                }
                break;
            case 'tipoPeso':
                setData({...data, [name]: value});
                if (data.pesoFacturado !== undefined) {
                    if (value === 'Gr') {
                        const valorFormat = parseFloat(data.pesoFacturado.toString().replace(',', '.'));
                        setData({
                            ...data,
                            totalCobrarSoles: valorFormat * (serviceSelected.valorSoles / 1000),
                            totalCobrarDolares: valorFormat * (serviceSelected.valorDolares / 1000),
                            tipoPeso: value
                        });
                    } else {
                        const valorFormat = parseFloat(data.pesoFacturado.toString().replace(',', '.'));
                        setData({
                            ...data,
                            totalCobrarSoles: valorFormat * serviceSelected.valorSoles,
                            totalCobrarDolares: valorFormat * serviceSelected.valorDolares,
                            tipoPeso: value
                        });
                    }
                }
                break;
            case 'tipoServicio':
                const findService = serviceType.find(x => x.id === value);
                setServiceSelected(findService!);
                if (data.pesoFacturado !== undefined && data.tipoPeso !== undefined) {
                    if (value === 'Gr') {
                        const valorFormat = parseFloat(data.pesoFacturado.toString().replace(',', '.'));
                        setData({
                            ...data,
                            totalCobrarSoles: valorFormat * (findService!.valorSoles / 1000),
                            totalCobrarDolares: valorFormat * (findService!.valorDolares / 1000)
                        });
                    } else {
                        const valorFormat = parseFloat(data.pesoFacturado.toString().replace(',', '.'));
                        setData({
                            ...data,
                            totalCobrarSoles: valorFormat * findService!.valorSoles,
                            totalCobrarDolares: valorFormat * findService!.valorDolares
                        });
                    }
                }
                break;
            default:
                setData({...data, [name]: value});
        }
        handleStep(!validData());
    }

    const validData = () => {
        let isValid = false;
        if (data.ancho !== '' && data.alto !== '' && data.profundidad !== '' && data.almacenOrigen !== '' &&
            data.almacenDestino !== '' && data.cajaNumero !== '' && data.pesoFacturado !== '' && data.tipoPeso !== ''
            && data.totalCobrarSoles !== '' && data.totalCobrarDolares !== '') {
            isValid = data.efectivoSoles !== '' ? true : data.efectivoDolares !== '' ? true : data.transferenciaSoles !== ''
                        ? true : data.transferenciaDolares !== '' ? true : data.yapeSoles !== '' ? true : data.yapeDolares !== ''
                        ? true : data.contraentregaDolares !== '' ? true : false;
        }
        return isValid;
    }

    useEffect(() => {
        const getServicesType = async () => {
            const response = await serviceTypeService.getAllServiceTypes(getDataUser().token);
            if (response) {
                setServiceType(response.data);
                console.log(serviceSelected);
            }
        }
        getServicesType();
    }, [])
    

    return (
        <Container>
            <Grid>
                <Card variant='outlined'>
                    <CardContent>
                        <Grid container
                            spacing={3}
                            sx={{ marginBottom: '2%' }}>
                            <Grid item xs={12} sm={4}>
                                <TextField type='text'
                                    placeholder='Ancho'
                                    name='ancho'
                                    fullWidth
                                    required
                                    variant='outlined'
                                    value={data.ancho}
                                    onChange={ changeInput }
                                    disabled={ isValidation }
                                    error={ hasError.ancho }
                                    helperText={ hasError.ancho ? 'Debe ingresar un ancho del paquete' : ''} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField type='text'
                                    placeholder='Alto'
                                    name='alto'
                                    fullWidth
                                    required
                                    variant='outlined'
                                    value={data.alto}
                                    onChange={ changeInput }
                                    disabled={ isValidation }
                                    error={ hasError.alto }
                                    helperText={ hasError.alto ? 'Debe ingresar un alto del paquete' : ''} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField type='text'
                                    placeholder='Profundidad'
                                    name='profundidad'
                                    fullWidth
                                    required
                                    variant='outlined'
                                    value={data.profundidad}
                                    onChange={ changeInput }
                                    disabled={ isValidation }
                                    error={ hasError.profundidad }
                                    helperText={ hasError.profundidad ? 'Debe ingresar una profundidad del paquete' : ''} />
                            </Grid>
                        </Grid>
                        <Grid container
                            spacing={3}
                            sx={{ marginBottom: '2%' }}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Almacen Origen</InputLabel>
                                    <Select value={data.almacenOrigen}
                                        label="Almacen Origen"
                                        name='almacenOrigen'
                                        onChange={ changeInput }
                                        error={ hasError.almacenOrigen }>
                                            <MenuItem value='Peru'>Peru</MenuItem>
                                            <MenuItem value='Venezuela'>Venezuela</MenuItem>
                                    </Select>
                                    {hasError.almacenOrigen ? <FormHelperText error={true}>Debe seleccionar un almacen de origen</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Almacen Destino</InputLabel>
                                    <Select value={data.almacenDestino}
                                        label="Almacen Destino"
                                        name='almacenDestino'
                                        onChange={ changeInput }
                                        error={ hasError.almacenDestino }>
                                            <MenuItem value='Peru'>Peru</MenuItem>
                                            <MenuItem value='Venezuela'>Venezuela</MenuItem>
                                    </Select>
                                    {hasError.almacenDestino ? <FormHelperText error={true}>Debe seleccionar un almacen de destino</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container
                            spacing={3}
                            sx={{ marginBottom: '2%' }}>
                            <Grid item xs={12} sm={4}>
                                <TextField type='text'
                                    placeholder='Caja Nro'
                                    name='cajaNumero'
                                    fullWidth
                                    required
                                    variant='outlined'
                                    value={data.cajaNumero}
                                    onChange={ changeInput }
                                    disabled={ isValidation }
                                    error={ hasError.cajaNumero }
                                    helperText={ hasError.cajaNumero ? 'Debe ingresar un numero de caja' : ''} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField type='text'
                                    placeholder='Peso Facturado'
                                    name='pesoFacturado'
                                    fullWidth
                                    required
                                    variant='outlined'
                                    value={data.pesoFacturado}
                                    onChange={ changeInput }
                                    disabled={ isValidation }
                                    error={ hasError.pesoFacturado }
                                    helperText={ hasError.pesoFacturado ? 'Debe ingresar un peso facturado' : ''} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Tipo Peso</InputLabel>
                                    <Select value={data.tipoPeso}
                                        label="Tipo Peso"
                                        name='tipoPeso'
                                        onChange={ changeInput }
                                        error={ hasError.tipoPeso }>
                                            <MenuItem value='Kg'>Kilogramos</MenuItem>
                                            <MenuItem value='Gr'>Gramos</MenuItem>
                                    </Select>
                                    {hasError.tipoPeso ? <FormHelperText error={true}>Debe seleccionar un tipo de peso</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container
                            spacing={3}
                            sx={{ marginBottom: '2%' }}>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Tipo Servicio</InputLabel>
                                    <Select value={data.tipoPeso}
                                        label="Tipo Servicio"
                                        name='tipoServicio'
                                        onChange={ changeInput }
                                        error={ hasError.tipoPeso }>
                                            {serviceType.map((st) => (
                                                <MenuItem value={st.id}>{st.descripcion}</MenuItem>
                                            ))}
                                    </Select>
                                    {hasError.tipoPeso ? <FormHelperText error={true}>Debe seleccionar un tipo de peso</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField type='text'
                                    placeholder='Total a Cobrar en Soles'
                                    name='totalCobrarSoles'
                                    fullWidth
                                    required
                                    variant='outlined'
                                    value={data.totalCobrarSoles}
                                    onChange={ changeInput }
                                    disabled={ true }
                                    error={ hasError.totalCobrarSoles }
                                    helperText={ hasError.totalCobrarSoles ? 'Debe ingresar un total en soles' : ''} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField type='text'
                                    placeholder='Total a Cobrar en Dolares'
                                    name='totalCobrarDolares'
                                    fullWidth
                                    required
                                    variant='outlined'
                                    value={data.totalCobrarDolares}
                                    onChange={ changeInput }
                                    disabled={ true }
                                    error={ hasError.totalCobrarDolares }
                                    helperText={ hasError.totalCobrarDolares ? 'Debe ingresar un total en dolares' : ''} />
                            </Grid>
                        </Grid>
                        <Grid container
                            sx={{ marginLeft: '53%' }}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Typography variant='h6'>Forma de Pago</Typography>
                                    <Grid container
                                        spacing={3}
                                        sx={{ marginBottom: '2%' }}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField type='text'
                                                placeholder='Efectivo en Soles'
                                                name='efectivoSoles'
                                                fullWidth
                                                required
                                                variant='outlined'
                                                value={data.efectivoSoles}
                                                onChange={ changeInput }
                                                disabled={ isValidation } />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField type='text'
                                                placeholder='Efectivo en Dolares'
                                                name='efectivoDolares'
                                                fullWidth
                                                required
                                                variant='outlined'
                                                value={data.efectivoDolares}
                                                onChange={ changeInput }
                                                disabled={ isValidation } />
                                        </Grid>
                                    </Grid>
                                    <Grid container
                                        spacing={3}
                                        sx={{ marginBottom: '2%' }}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField type='text'
                                                placeholder='Transferencia en Soles'
                                                name='transferenciaSoles'
                                                fullWidth
                                                required
                                                variant='outlined'
                                                value={data.transferenciaSoles}
                                                onChange={ changeInput }
                                                disabled={ isValidation } />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField type='text'
                                                placeholder='Transferencia en Dolares'
                                                name='transferenciaDolares'
                                                fullWidth
                                                required
                                                variant='outlined'
                                                value={data.transferenciaDolares}
                                                onChange={ changeInput }
                                                disabled={ isValidation } />
                                        </Grid>
                                    </Grid>
                                    <Grid container
                                        spacing={3}
                                        sx={{ marginBottom: '2%' }}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField type='text'
                                                placeholder='Yape en Soles'
                                                name='yapeSoles'
                                                fullWidth
                                                required
                                                variant='outlined'
                                                value={data.yapeSoles}
                                                onChange={ changeInput }
                                                disabled={ isValidation } />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField type='text'
                                                placeholder='Yape en Dolares'
                                                name='yapeDolares'
                                                fullWidth
                                                required
                                                variant='outlined'
                                                value={data.yapeDolares}
                                                onChange={ changeInput }
                                                disabled={ isValidation } />
                                        </Grid>
                                    </Grid>
                                    <Grid container
                                        spacing={3}
                                        sx={{ marginBottom: '2%' }}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField type='text'
                                                placeholder='Contraentrega en Dolares'
                                                name='contraentregaDolares'
                                                fullWidth
                                                required
                                                variant='outlined'
                                                value={data.contraentregaDolares}
                                                onChange={ changeInput }
                                                disabled={ isValidation } />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Container>
    )
}
