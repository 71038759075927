import { CustomResponse } from '../models';
import { Login, User, UserRequest } from '../models/user';
import api from './ConfigService';

export const UserService = () => {    
    const doLogin = async (credential: Login): Promise<CustomResponse<User>> => {
        let user: CustomResponse<User> = {} as CustomResponse<User>;
        await api.post('Usuarios/DoLogin', credential)
        .then(response => {
            user = response.data;
        }).catch(error => {
            user = error;
        });
        return user;
    }

    const getAllUsers = async (token: string): Promise<CustomResponse<User[]>> => {
        let users: CustomResponse<User[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get('Usuarios', { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            users = response.data;
        }).catch(error => {
            users = error;
        });
        return users;
    }

    const getUserById = async (id: string, token: string): Promise<CustomResponse<User>> => {
        let user: CustomResponse<User> = {} as CustomResponse<User>;
        await api.get(`Usuarios/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            user = response.data;
        }).catch(error => {
            user = error;
        });
        return user;
    }

    const createUser = async (userRequest: UserRequest, token: string): Promise<CustomResponse<User>> => {
        let user: CustomResponse<User> = {} as CustomResponse<User>;
        await api.post('Usuarios', userRequest, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            user = response.data;
        }).catch(error => {
            user = error;
        });
        return user;
    }

    const updateUser = async (userRequest: UserRequest, token: string): Promise<CustomResponse<User>> => {
        let user: CustomResponse<User> = {} as CustomResponse<User>;
        await api.put('Usuarios', userRequest, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            user = response.data;
        }).catch(error => {
            user = error;
        });
        return user;
    }

    const deleteUser = async (id: string, token: string): Promise<CustomResponse<boolean>> => {
        let flat: CustomResponse<boolean> = {} as CustomResponse<boolean>;
        await api.delete(`Usuarios/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            flat = response.data;
        }).catch(error => {
            flat = error;
        });
        return flat;
    }

    return {
        doLogin,
        getAllUsers,
        getUserById,
        createUser,
        updateUser,
        deleteUser
    }
}