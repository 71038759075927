import CssBaseline from '@mui/material/CssBaseline';
import { Container } from '@mui/material';
import { Sidenav } from '../../components/Sidenav';
import { Appbar } from '../../components/Appbar';
import { ContentRouter } from '../../router/ContentRouter';
import { AuthVerify } from '../../components/AuthVerify';
import { removeDataUser } from '../../storage/userStorage';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function App() {
  const navigation = useNavigate();
  
  const logOut = () => {
    removeDataUser();
  };

  useEffect(() => {
    if (localStorage.length === 0)
      navigation('/auth');
  }, [navigation])
  
  return (
    <>
      <CssBaseline />
      <Sidenav />
      <Appbar />
      <Container sx={{
        marginLeft: '20%',
        paddingTop: '5%',
      }}>
        <ContentRouter />
      </Container>
      <AuthVerify logOut={logOut}/>
    </>
  );
}
