import { Box, Button, Card, CardContent, Container, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SweetAlert } from "../../../components/SweetAlert";
import { Client } from "../../../models/client";
import { RateDay } from "../../../models/rateDay";
import { Product, Tracking } from "../../../models/tracking";
import { RateDayService } from "../../../services/RateDayService";
import { TrackingService } from "../../../services/TrackingService";
import { getDataUser } from "../../../storage/userStorage";
import { StepAddressee } from "./StepAddressee";
import { StepGeneral } from "./StepGeneral";
import { StepProducts } from "./StepProducts";
import { StepSender } from "./StepSender";

const steps = ['Datos del remitente', 'Datos del destinatario', 'Carga de productos', 'Datos generales'];

export const TrackingAction = () => {
    const trackingService = TrackingService();
    const rateDayService = RateDayService();
    const navigation = useNavigate();
    const sweetAlert = SweetAlert();
    const [activeStep, setActiveStep] = useState<number>(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const [clientSender, setClientSender] = useState<Client>({} as Client);
    const [clientAddressee, setClientAddressee] = useState<Client>({} as Client);
    const [products, setProducts] = useState<Product[]>([]);
    const [headerTracking, setHeaderTracking] = useState({} as Tracking);
    const [enableButton, setEnableButton] = useState<boolean>(true);
    const [rateDay, setRateDay] = useState<RateDay>({} as RateDay);

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        if (activeStep < 3) {
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }
            setEnableButton(true);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        } else {
            saveTracking();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const showCardStep = () => {
        switch (activeStep) {
            case 0:
                return <StepSender data={clientSender} setData={setClientSender} handleStep={handleEnableButton} />;
            case 1:
                return <StepAddressee data={clientAddressee} setData={setClientAddressee} handleStep={handleEnableButton} />;
            case 2:
                return <StepProducts data={products} setData={setProducts} rateDay={rateDay} handleStep={handleEnableButton} />
            case 3:
                return <StepGeneral data={headerTracking} setData={setHeaderTracking} handleStep={handleEnableButton} />
            default:
                return <></>;
        }
    }

    const handleEnableButton = (hasData: boolean) => {
        switch (activeStep) {
            case 0:
                setEnableButton(hasData);
                break;
            case 1:
                setEnableButton(hasData);
                break;
            case 2:
                setEnableButton(hasData);
                break;
            case 3:
                setEnableButton(hasData);
                break;
            default:
                break;
        }
    }

    const saveTracking = async () => {
        setEnableButton(true);
        headerTracking.idRemitente = clientSender.id;
        headerTracking.idDestinatario = clientAddressee.id;
        headerTracking.estatus = 'Recibido'
        headerTracking.medidas = `${headerTracking.ancho}x${headerTracking.alto}x${headerTracking.profundidad}`;
        headerTracking.cajaNumero = parseInt(headerTracking.cajaNumero.toString());
        headerTracking.pesoFacturado = parseFloat(headerTracking.pesoFacturado.toString().replace(',', '.'));
        headerTracking.efectivoSoles = headerTracking.efectivoSoles !== undefined ? parseFloat(headerTracking.efectivoSoles.toString().replace(',', '.')) : 0;
        headerTracking.efectivoDolares = headerTracking.efectivoDolares !== undefined ? parseFloat(headerTracking.efectivoDolares.toString().replace(',', '.')) : 0;
        headerTracking.transferenciaSoles = headerTracking.transferenciaSoles !== undefined ? parseFloat(headerTracking.transferenciaSoles.toString().replace(',', '.')) : 0;
        headerTracking.transferenciaDolares = headerTracking.transferenciaDolares !== undefined ? parseFloat(headerTracking.transferenciaDolares.toString().replace(',', '.')) : 0;
        headerTracking.yapeSoles = headerTracking.yapeSoles !== undefined ? parseFloat(headerTracking.yapeSoles.toString().replace(',', '.')) : 0;
        headerTracking.yapeDolares = headerTracking.yapeDolares !== undefined ? parseFloat(headerTracking.yapeDolares.toString().replace(',', '.')) : 0;
        headerTracking.contraentregaDolares = headerTracking.contraentregaDolares !== undefined ? parseFloat(headerTracking.contraentregaDolares.toString().replace(',', '.')) : 0;
        headerTracking.productos = products;
        console.log(headerTracking);
        const response = await trackingService.createTracking(headerTracking, getDataUser().token);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess)
            setEnableButton(false);
        } else {
            sweetAlert.showAlert('El tracking se creo correctamente', response.isSuccess);
            navigation('/historial-envios');
            setEnableButton(false);
        }
    }

    useEffect(() => {
        const getRateDay = async () => {
            const response = await rateDayService.getRateDay(getDataUser().token);
            if (response) {
                setRateDay(response.data);
            }
        }
        getRateDay();
    }, []);
    

    return (
        <Container>
            <Typography variant="body2">Tracking</Typography>
            <Grid sx={{
                marginTop: '5%'
            }}>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <Box p={3}>
                        <Box mt={1.625}>
                            <Card variant='outlined'
                                sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)' }}>
                                <CardContent>
                                    {showCardStep()}
                                </CardContent>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginLeft: '1rem', marginRight: '1rem', marginBottom: '1rem' }}>
                                    <Button color='inherit'
                                        variant='outlined'
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}>
                                        Atras
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleNext}
                                        variant={activeStep === steps.length - 1 ? 'contained' : 'outlined'}
                                        disabled={enableButton}
                                        color='primary'>
                                        {activeStep === steps.length - 1 ? 'Procesar' : 'Proximo'}
                                    </Button>
                                </Box>
                            </Card>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Container>
    );
}
