import { CustomResponse } from '../models';
import { BillPayDetail } from '../models/billPay';
import api from './ConfigService';

export const BillPayDetailService = () => {
    const getAllBillPayDetails = async (token: string, id: string): Promise<CustomResponse<BillPayDetail[]>> => {
        let billPayDetails: CustomResponse<BillPayDetail[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get(`CuentaPagarDetalle?idCuentaPagar=${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            billPayDetails = response.data;
        }).catch(error => {
            billPayDetails = error;
        });
        return billPayDetails;
    }

    const getBillPayDetailById = async (id: string, token: string): Promise<CustomResponse<BillPayDetail>> => {
        let billPayDetail: CustomResponse<BillPayDetail> = {} as CustomResponse<BillPayDetail>;
        await api.get(`CuentaPagarDetalle/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            billPayDetail = response.data;
        }).catch(error => {
            billPayDetail = error;
        });
        return billPayDetail;
    }

    const deleteBillPayDetails = async (id: string, token: string): Promise<CustomResponse<boolean>> => {
        let flat: CustomResponse<boolean> = {} as CustomResponse<boolean>;
        await api.delete(`CuentaPagarDetalle/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            flat = response.data;
        }).catch(error => {
            flat = error;
        });
        return flat;
    }

    return {
        getAllBillPayDetails,
        getBillPayDetailById,
        deleteBillPayDetails
    }
}