import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Collapse, Container, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import logo from '../assets/images/galivica_blanco.png';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonIcon from '@mui/icons-material/Person';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ChecklistIcon from '@mui/icons-material/Checklist';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { getDataUser } from '../storage/userStorage';

export const Sidenav = () => {
    const navigation = useNavigate();
    const [open, setOpen] = useState(true);
    const [profile, setProfile] = useState(getDataUser().perfil)
  
    const handleClick = () => {
      setOpen(!open);
    };

    const goView = (view: string) => {
        navigation(view);
    }

    return (
        <Box sx={{
            width: '20%',
            minHeight: '100%',
            backgroundColor: 'primary.dark',
            position: 'fixed'
        }}>
            <Container sx={{
                marginTop: '25%'
            }}>
                <img src={logo}
                    alt='Logo'
                    width='100%'/>
            </Container>
            <List
                sx={{ width: '100%',
                    maxWidth: '100%',
                    bgcolor: 'primary.dark',
                    color: 'white',
                    marginTop: '10%'
                }}
                component="nav">
                <ListItemButton onClick={() => goView('/tracking')}>
                    <ListItemIcon>
                        <LocalShippingIcon sx={{
                            color: 'white'
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Tracking" />
                </ListItemButton>
                <ListItemButton onClick={() => goView('/historial-envios')}>
                    <ListItemIcon>
                        <SummarizeIcon sx={{
                            color: 'white'
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Historial de envios" />
                </ListItemButton>
                {profile === 'Administrador' ?
                    <ListItemButton onClick={() => goView('/seguimiento')}>
                        <ListItemIcon>
                            <EventAvailableIcon sx={{
                                color: 'white'
                            }}/>
                        </ListItemIcon>
                        <ListItemText primary="Seguimiento" />
                    </ListItemButton> : <></>
                }
                {profile === 'Administrador' ?
                    <ListItemButton onClick={() => goView('/cuadre-caja')}>
                        <ListItemIcon>
                            <PaymentsIcon sx={{
                                color: 'white'
                            }}/>
                        </ListItemIcon>
                        <ListItemText primary="Cuadre de caja diario" />
                    </ListItemButton> : <></>
                }
                {profile === 'Administrador' ?
                    <ListItemButton onClick={() => goView('/cuentas-pagar')}>
                        <ListItemIcon>
                            <AccountBalanceIcon sx={{
                                color: 'white'
                            }}/>
                        </ListItemIcon>
                        <ListItemText primary="Cuentas por pagar" />
                    </ListItemButton> : <></>
                }
                <ListItemButton onClick={() => goView('/control-gastos')}>
                    <ListItemIcon>
                        <MonetizationOnIcon sx={{
                            color: 'white'
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Control de gastos y compras" />
                </ListItemButton>
                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                        <SettingsIcon sx={{
                            color: 'white'
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Administracion" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}
                            onClick={() => goView('/administracion/clientes')}>
                            <ListItemIcon>
                                <ContactMailIcon sx={{
                                    color: 'white'
                                }}/>
                            </ListItemIcon>
                            <ListItemText primary="Clientes" />
                        </ListItemButton>
                        {profile === 'Administrador' ?
                            <>
                                <ListItemButton sx={{ pl: 4 }}
                                    onClick={() => goView('/administracion/proveedores')}>
                                    <ListItemIcon>
                                        <SupervisedUserCircleIcon sx={{
                                            color: 'white'
                                        }}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Proveedores" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }}
                                    onClick={() => goView('/administracion/usuarios')}>
                                    <ListItemIcon>
                                        <PersonIcon sx={{
                                            color: 'white'
                                        }}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Usuarios" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }}
                                    onClick={() => goView('/administracion/tipo-servicios')}>
                                    <ListItemIcon>
                                        <ChecklistIcon sx={{
                                            color: 'white'
                                        }}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Tipo de servicios" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }}
                                    onClick={() => goView('/administracion/tasa-dia')}>
                                    <ListItemIcon>
                                        <QueryStatsIcon sx={{
                                            color: 'white'
                                        }}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Tasa del dia" />
                                </ListItemButton>
                            </> : <></>
                        }
                    </List>
                </Collapse>
            </List>
        </Box>
    )
}
