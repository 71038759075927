import { CustomResponse } from '../models';
import { BillPay } from '../models/billPay';
import BillPayRequest from '../models/billPay/BillPayRequest';
import api from './ConfigService';

export const BillPayService = () => {
    const getAllBillPays = async (token: string): Promise<CustomResponse<BillPay[]>> => {
        let billPays: CustomResponse<BillPay[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get('CuentaPagar', { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            billPays = response.data;
        }).catch(error => {
            billPays = error;
        });
        return billPays;
    }

    const getBillPayById = async (id: string, token: string): Promise<CustomResponse<BillPay>> => {
        let billPay: CustomResponse<BillPay> = {} as CustomResponse<BillPay>;
        await api.get(`CuentaPagar/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            billPay = response.data;
        }).catch(error => {
            billPay = error;
        });
        return billPay;
    }

    const createBillPay = async (billPayRequest: BillPay, token: string): Promise<CustomResponse<BillPay>> => {
        let billPay: CustomResponse<BillPay> = {} as CustomResponse<BillPay>;
        await api.post('CuentaPagar', billPayRequest, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            billPay = response.data;
        }).catch(error => {
            billPay = error;
        });
        return billPay;
    }

    const updateBillPay = async (clientRequest: BillPayRequest, token: string): Promise<CustomResponse<BillPay>> => {
        let billPay: CustomResponse<BillPay> = {} as CustomResponse<BillPay>;
        await api.put('CuentaPagar', clientRequest, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            billPay = response.data;
        }).catch(error => {
            billPay = error;
        });
        return billPay;
    }

    const deleteBillPay = async (id: string, token: string): Promise<CustomResponse<boolean>> => {
        let flat: CustomResponse<boolean> = {} as CustomResponse<boolean>;
        await api.delete(`CuentaPagar/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            flat = response.data;
        }).catch(error => {
            flat = error;
        });
        return flat;
    }

    return {
        getAllBillPays,
        getBillPayById,
        createBillPay,
        updateBillPay,
        deleteBillPay
    }
}