import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Box, Button, Card, CardContent, Container, FormControlLabel, Grid, InputBase, Paper, Radio, RadioGroup, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SweetAlert } from '../../../components/SweetAlert';
import { ExpensePurchaseControl } from '../../../models/expensePurchaseControl';
import { ExpensePurchaseControlService } from '../../../services/ExpensePurchaseControlService';
import { getDataUser } from '../../../storage/userStorage';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2e3192',
      color: 'white',
      fontSize: '1rem',
      textAlign: 'center'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '0.9rem',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export const ExpensePurchaseControlDetail = () => {
    const navigation = useNavigate();
    const expensePurchaseControlService = ExpensePurchaseControlService();
    const sweetAlert = SweetAlert();
    const [expensePurchaseControl, setExpensePurchaseControl] = useState<ExpensePurchaseControl[]>([]);
    const [expensePurchaseControlFilter, setExpensePurchaseControlFilter] = useState<ExpensePurchaseControl[]>([]);
    const [dateFilter, setDateFilter] = useState({
        fecha: new Date(),
        fechaDesde: new Date(),
        fechaHasta: new Date()
    });
    const [expensePurchaseControlSolesTotal, setExpensePurchaseControlSolesTotal] = useState<number>(0);
    const [expensePurchaseControlDolaresTotal, setExpensePurchaseControlDolaresTotal] = useState<number>(0);
    const [radioValue, setRadioValue] = useState<string>('');
    const [showDateFilter, setShowDateFilter] = useState<boolean>(false);
    const [showDateRangeFilter, setShowDateRangeFilter] = useState<boolean>(false);

    const actionExpensePurchaseControl = (id?: string) => {
        if (id === undefined)
            navigation('/control-gastos/0');
        else
            navigation(`/control-gastos/${id}`);
    }

    const deleteExpensePurchaseControl = async (id: string, description: string) => {
        let result = await sweetAlert.showAlertWithButton(`el control de gasto y compra ${description}`);
        if (result) {
            const response = await expensePurchaseControlService.deleteExpensePurchaseControl(id, getDataUser().token);
            if (!response.isSuccess) {
                sweetAlert.showAlert(response.message, response.isSuccess)
            }
            else {
                sweetAlert.showAlert('El control de gasto y compra se elimino correctamente', response.isSuccess);
                setExpensePurchaseControlFilter(expensePurchaseControl.filter(x => x.id !== id));
            }
        }
    }

    const searchExpensePurchaseControl = (event: any) => {
        if (event.target.value !== '') {
            const newData = expensePurchaseControl.filter(function(item) {
                const itemDescripcion = item.descripcion.toUpperCase();
                const textData = event.target.value.toUpperCase();
                return itemDescripcion.indexOf(textData) > -1;
            });
            setExpensePurchaseControlFilter(newData);
        } else {
            setExpensePurchaseControlFilter(expensePurchaseControl);
        }
    }

    const handleRadioChange = (event: any) => {
        setRadioValue(event.target.value);
        if (event.target.value === 'rangoFechas') {
            setShowDateRangeFilter(true);
            setShowDateFilter(false);
        } else {
            setShowDateFilter(true);
            setShowDateRangeFilter(false);
        }
    }

    const changeDate = (newValue: any, fecha: string) => {
        setExpensePurchaseControl([]);
        setExpensePurchaseControlFilter([]);
        calculateExpenseTotal([]);
        var date = new Date();
        date.setDate(newValue.$D);
        date.setMonth(newValue.$M)
        date.setFullYear(newValue.$y);
        if (fecha === '') {
            setDateFilter({...dateFilter, fecha: date});
        } else {
            switch (fecha) {
                case 'fechaDesde':
                    setDateFilter({...dateFilter, fechaDesde: date});
                    break;
                case 'fechaHasta':
                    setDateFilter({...dateFilter, fechaHasta: date});
                    break;
            }
        }
    }

    const getExpensePurchaseControl = async (date: string) => {
        const data = await expensePurchaseControlService.getExpensePurchaseControlByDate(date, getDataUser().token);
        setExpensePurchaseControl(data.data);
        setExpensePurchaseControlFilter(data.data);
        calculateExpenseTotal(data.data);
    }

    const getExpensePurchaseControlRange = async (dateStart: string, dateEnd: string) => {
        const data = await expensePurchaseControlService.getExpensePurchaseControlByRange(dateStart, dateEnd, getDataUser().token);
        setExpensePurchaseControl(data.data);
        setExpensePurchaseControlFilter(data.data);
        calculateExpenseTotal(data.data);
    }

    const calculateExpenseTotal = (data: ExpensePurchaseControl[]) => {
        let totalSoles: number = 0;
        let totalDolares: number = 0;
        data.forEach(element => {
            if (element.moneda === 'Soles')
                totalSoles += element.valor;
            else
                totalDolares += element.valor;
        });
        setExpensePurchaseControlSolesTotal(totalSoles);
        setExpensePurchaseControlDolaresTotal(totalDolares);
    }

    const resetFilter = () => {
        setRadioValue('');
        getExpensePurchaseControl(new Date().toLocaleDateString().replaceAll('/', '-'));
        setDateFilter({...dateFilter, fecha: new Date(), fechaDesde: new Date(), fechaHasta: new Date()});
        setShowDateFilter(false);
    }

    const applyFilter = () => {
        switch (radioValue) {
            case 'porFecha':
                getExpensePurchaseControl(dateFilter.fecha.toLocaleDateString().replaceAll('/', '-'));
                break;
            case 'rangoFechas':
                if (new Date(dateFilter.fechaDesde) < new Date(dateFilter.fechaHasta)) {
                    getExpensePurchaseControlRange(dateFilter.fechaDesde.toLocaleDateString().replaceAll('/', '-'),
                                                    dateFilter.fechaHasta.toLocaleDateString().replaceAll('/', '-'));
                } else {
                    sweetAlert.showAlert('La fecha desde no puede ser mayor o igual a la fecha hasta', false);
                }
                break;
        }
    }

    useEffect(() => {
        const getDate = (): Date => {
            var today = new Date();
            today.setDate(today.getDate());
            today.setFullYear(today.getFullYear());
            today.setMonth(today.getMonth());
            return today;
        }
        getExpensePurchaseControl(getDate().toLocaleDateString().replaceAll('/', '-'));
    }, [])
    
    return (
        <Container>
            <Typography variant="body2">Control de gastos y compras</Typography>
            <Button variant='contained'
                    color='primary'
                    sx={{
                        marginLeft: '80%'
                    }}
                    onClick={() => actionExpensePurchaseControl()}>
                Crear gasto o compra
            </Button>
            <Grid sx={{
                marginTop: '2%'
            }}>
                <Card variant='outlined'
                    sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)', marginBottom: '2rem' }}>
                    <CardContent>
                        <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={radioValue}
                            onChange={handleRadioChange} >
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel value="porFecha" control={<Radio />} label="Por Fecha" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel value="rangoFechas" control={<Radio />} label="Rango de fechas" />
                                </Grid>
                            </Grid>
                        </RadioGroup>
                        {showDateFilter ?
                            <Grid container
                                sx={{ marginTop: '1rem' }}>
                                <Grid item xs={12} sm={2}
                                    sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                                    Fecha
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <DatePicker slotProps={{ textField: { fullWidth: true } }}
                                        onChange={ (newValue) => changeDate(newValue, '') }
                                        value={dayjs(dateFilter.fecha)}
                                        format='DD/MM/YYYY' />
                                </Grid>
                            </Grid>
                            : ''
                        }
                        {showDateRangeFilter ?
                            <Grid container
                                sx={{ marginTop: '1rem' }}>
                                <Grid item xs={12} sm={2}
                                    sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                                    Fecha desde
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <DatePicker slotProps={{ textField: { fullWidth: true } }}
                                        onChange={ (newValue) => changeDate(newValue, 'fechaDesde') }
                                        value={dayjs(dateFilter.fechaDesde)}
                                        format='DD/MM/YYYY' />
                                </Grid>
                                <Grid item xs={12} sm={2}
                                    sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                                    Fecha hasta
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <DatePicker slotProps={{ textField: { fullWidth: true } }}
                                        onChange={ (newValue) => changeDate(newValue, 'fechaHasta') }
                                        value={dayjs(dateFilter.fechaHasta)}
                                        format='DD/MM/YYYY' />
                                </Grid>
                            </Grid>
                            : ''
                        }
                    </CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginLeft: '1rem', marginRight: '1rem', marginBottom: '1rem' }}>
                        <Button color='inherit'
                            variant='outlined'
                            disabled={radioValue === ''}
                            onClick={resetFilter}
                            sx={{ mr: 1 }}>
                            Limpiar filtro
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={applyFilter}
                            disabled={radioValue === ''}
                            variant='contained'
                            color='primary'>
                            Filtrar
                        </Button>
                    </Box>
                </Card>
                <Paper component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto', marginBottom: '2rem' }} >
                    <InputBase sx={{ ml: 1, flex: 1 }}
                        placeholder="Buscar por descripcion"
                        name='inputSearch'
                        onChange={ (event) => searchExpensePurchaseControl(event) }/>
                </Paper>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%', minHeight: '20%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Descripcion</StyledTableCell>
                                <StyledTableCell width='15%'>Referencia</StyledTableCell>
                                <StyledTableCell width='15%'>Monto</StyledTableCell>
                                <StyledTableCell width='15%'>Moneda</StyledTableCell>
                                <StyledTableCell width='20%' align='right'></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {expensePurchaseControlFilter?.map((purchaseControl) => (
                                <StyledTableRow key={purchaseControl.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {purchaseControl.descripcion}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>{purchaseControl.referencia}</StyledTableCell>
                                    <StyledTableCell align='center'>{new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(purchaseControl.valor)}</StyledTableCell>
                                    <StyledTableCell align='center'>{purchaseControl.moneda}</StyledTableCell>
                                    <StyledTableCell align='center'>
                                        {dateFilter.fecha.toLocaleDateString() === new Date().toLocaleDateString() ?
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Button onClick={() => actionExpensePurchaseControl(purchaseControl.id)}>
                                                        <ModeEditIcon />
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Button onClick={() => deleteExpensePurchaseControl(purchaseControl.id, `${purchaseControl.descripcion}`)}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            : ''
                                        }
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Typography variant='body1' align='right' sx={{ marginTop: '1rem', marginRight: '1rem' }}>
                            Total pagado en soles: <strong>${new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(expensePurchaseControlSolesTotal)}</strong>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Typography variant='body1' align='right' sx={{ marginTop: '1rem', marginRight: '1rem' }}>
                            Total pagado en dolares: <strong>${new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(expensePurchaseControlDolaresTotal)}</strong>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
