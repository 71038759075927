import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import PageLayout from '../../../layouts/PageLayout';
import Footer from './Footer';
import { Box, Typography } from '@mui/material';
import banner from '../../../assets/images/banner.png';

function BasicLayout({ title, description, children }: any) {
  return (
    <PageLayout>
      <Box
        width='calc(100% - 2rem)'
        height='12rem'
        borderRadius='1rem'
        mx={2}
        my={2}
        pt={6}
        pb={28}
        sx={{
          backgroundImage: `url(${banner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'primary'
        }}
      >
        <Grid container spacing={3} justifyContent='center' sx={{ textAlign: 'center' }}>
          <Grid item xs={10} lg={4}>
            <Box mt={6} mb={1}>
              <Typography variant='h2' color='white'>
                {title}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant='body2' color='white' fontWeight='regular'>
                {description}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={{ xs: -26, lg: -24 }} px={1} width='calc(100% - 2rem)' mx='auto'>
        <Grid container spacing={1} justifyContent='center'>
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </PageLayout>
  );
}

// Setting default values for the props of BasicLayout
BasicLayout.defaultProps = {
  title: '',
  description: '',
};

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
