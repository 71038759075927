import { Button, Card, CardContent, Container, Grid, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DailyCashBalance } from "../../../models/dailyCashBalance/DailyCashBalance";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { getDataUser } from '../../../storage/userStorage';
import { DailyCashBalanceService } from '../../../services/DailyCashBalanceService';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { SweetAlert } from '../../../components/SweetAlert';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2e3192',
      color: 'white',
      fontSize: '1rem',
      textAlign: 'center'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '0.9rem',
    },
  }));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const DailyCashBalanceView = () => {
    const dailyCashBalanceService = DailyCashBalanceService();
    const sweetAlert = SweetAlert();
    const [dailyCashBalance, setDailyCashBalance] = useState<DailyCashBalance[]>([]);
    const [dateFilter, setDateFilter] = useState({
        fecha: new Date()
    });
    const [dailyBalance, setDailyBalance] = useState({
        ingreso: 0,
        egreso: 0,
        balance: 0
    });
    const [getColor, setGetColor] = useState('');

    const exportExcel = () => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        let trackingExcel: any = [];
        dailyCashBalance.map((item) => {
            let obj = {
                'Fecha': new Date(item.fecha).toLocaleDateString('en-GB'),
                'Descripcion': item.descripcion,
                'Ingreso': new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.ingreso),
                'Egreso': new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.egreso),
                'Forma de Pago': item.formaPago
            };
            trackingExcel.push(obj);
        });
        const ws = XLSX.utils.json_to_sheet(trackingExcel);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'Cuadre de Caja Diario Galivica Express' + fileExtension);
    }

    const changeDate = (newValue: any) => {
        setDailyCashBalance([]);
        var date = new Date();
        date.setDate(newValue.$D);
        date.setMonth(newValue.$M)
        date.setFullYear(newValue.$y);
        setDateFilter({...dateFilter, fecha: date});
    }

    const searchDailyCashBalance = async () => {
        const response = await dailyCashBalanceService.getDailyCashBalance(dateFilter.fecha.toLocaleDateString('en-US').replaceAll('/', '-'), getDataUser().token);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess)
        } else {
            setDailyCashBalance(response.data);
            calculateBalance(response.data);
        }
    }

    const calculateBalance = (data: DailyCashBalance[]) => {
        dailyBalance.ingreso = 0;
        dailyBalance.egreso = 0;
        dailyBalance.balance = 0;
        data?.map((i) => {
            dailyBalance.ingreso += i.ingreso;
            dailyBalance.egreso += i.egreso;
        });
        dailyBalance.balance = dailyBalance.ingreso - dailyBalance.egreso;
        setGetColor(dailyBalance.balance < 0 ? '#ea0606' : '#2e3192');
    }

    useEffect(() => {
        const getDailyCashBalance = async () => {
            const response = await dailyCashBalanceService.getDailyCashBalance(new Date().toLocaleDateString().replaceAll('/', '-'), getDataUser().token);
            setDailyCashBalance(response.data);
            calculateBalance(response.data);
        }
        getDailyCashBalance();
    }, [])
    

    return (
        <Container>
            <Typography variant="body2">Cuadre de caja diario</Typography>
            <Button variant='contained'
                    color='primary'
                    sx={{
                        marginLeft: '90%'
                    }}
                    onClick={() => exportExcel()}>
                Exportar
            </Button>
            <Grid sx={{
                marginTop: '2%',
                marginBottom: '2%'
            }}>
                <Card variant='outlined'
                    sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)', marginBottom: '2rem' }}>
                    <CardContent>
                        <Grid container
                            sx={{ marginTop: '1rem' }}>
                            <Grid item xs={12} sm={2}
                                sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                                Fecha
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <DatePicker slotProps={{ textField: { fullWidth: true } }}
                                    onChange={ (newValue) => changeDate(newValue) }
                                    value={dayjs(dateFilter.fecha)}
                                    format='DD/MM/YYYY' />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Button variant='contained'
                                    color='primary'
                                    onClick={() => searchDailyCashBalance()}
                                    sx={{ paddingTop: '6%', paddingBottom: '6%', marginLeft: '5%', width: '95%' }}>
                                    Buscar
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%', minHeight: '20%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Fecha</StyledTableCell>
                                <StyledTableCell>Descripcion</StyledTableCell>
                                <StyledTableCell>Ingreso</StyledTableCell>
                                <StyledTableCell>Egreso</StyledTableCell>
                                <StyledTableCell>Forma de Pago</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dailyCashBalance?.map((daily) => (
                                <StyledTableRow key={daily.id}>
                                    <StyledTableCell component="th" scope="row" align='center'>
                                        {new Date(daily.fecha).toLocaleDateString('en-GB')}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {daily.descripcion}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        {new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(daily.ingreso)}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        {new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(daily.egreso)}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>{daily.formaPago}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {dailyCashBalance?.length > 0 ?
                    <Grid container
                        sx={{ marginTop: '1rem' }}>
                        <Grid item xs={12} sm={2}
                            sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                            Total Ingreso:
                        </Grid>
                        <Grid item xs={12} sm={2}
                            sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                            <strong>{new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(dailyBalance.ingreso)}</strong>
                        </Grid>
                        <Grid item xs={12} sm={2}
                            sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                            Total Egreso:
                        </Grid>
                        <Grid item xs={12} sm={2}
                            sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                            <strong>{new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(dailyBalance.egreso)}</strong>
                        </Grid>
                        <Grid item xs={12} sm={2}
                            sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                            Balance:
                        </Grid>
                        <Grid item xs={12} sm={2}
                            sx={{ paddingTop: '1rem', paddingLeft: '3rem', color: getColor }}>
                            <strong>{new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(dailyBalance.balance)}</strong>
                        </Grid>
                    </Grid>
                    : ''
                }
            </Grid>
        </Container>
    )
}
