import { Route, Routes, Navigate } from 'react-router-dom';
import SignIn from '../views/authentication/SignIn';
import Main from '../views/app/Main';

export const AppRouter = () => {
    return (
        <Routes>
            <Route path='/auth/*' element={ <SignIn /> } />
            <Route path='/*' element={ <Main /> } />

            <Route path='/*' element={ <Navigate to='/auth/login' /> } />
        </Routes>
    )
}
