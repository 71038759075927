import { Box, Button, Card, CardActions, CardContent, Container, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from '../../../models/user';
import { UserService } from '../../../services/UserService';
import { ProfileService } from '../../../services/ProfileService';
import { getDataUser } from '../../../storage/userStorage';
import { SweetAlert } from "../../../components/SweetAlert";
import { Profile } from '../../../models/profile';

export const UserActions = () => {
    const navigation = useNavigate();
    const userService = UserService();
    const profileService = ProfileService();
    const sweetAlert = SweetAlert();
    var md5 = require('md5');
    const [user, setUser] = useState<User>({} as User);
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const {id} = useParams();
    const [hasError, setHasError] = useState({
        nombre: false,
        apellido: false,
        nombreUsuario: false,
        contrasena: false,
        idPerfil: false
    });
    const [isValidation, setIsValidation] = useState(false);

    const changeInput = (event: any) => {
        const {name, value} = event.target;
        setUser({ ...user, [name]: value });
    }

    const validateField = async () => {
        if (user.nombre === undefined || user.apellido === undefined ||
            user.nombreUsuario === undefined || user.contrasena === undefined ||
            user.idPerfil === undefined) {
            setHasError({
                nombre: user.nombre === undefined,
                apellido: user.apellido === undefined,
                nombreUsuario: user.nombreUsuario === undefined,
                contrasena: user.contrasena === undefined,
                idPerfil: user.idPerfil === undefined
            });
            return true;
        }
        return false;
    }

    const actionClient = async () => {
        setIsValidation(true);
        if (await validateField()) {
            setIsValidation(false);
            return;
        }
        user.contrasena = md5(user.contrasena);
        const response = id !== '0' ? await userService.updateUser(user, getDataUser().token)
                        : await userService.createUser(user, getDataUser().token);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess)
            setIsValidation(false);
        }
        else {
            sweetAlert.showAlert(id !== '0' ? 'El usuario se modifico correctamente' : 'El usuario se creo correctamente', response.isSuccess);
            navigation('/administracion/usuarios');
            setIsValidation(false);
        }
    }

    useEffect(() => {
        const getUser = async (id: any) => {
            const data = await userService.getUserById(id, getDataUser().token);
            setUser(data.data);
        }
        if (id !== '0') getUser(id);
        const getProfiles = async () => {
            const data = await profileService.getAllProfiles(getDataUser().token);
            setProfiles(data.data);
        }
        getProfiles();
    }, [])

    return (
        <Container>
            <Typography variant="body2">Administracion/{id !== '0' ? 'Modificar Usuario' : 'Crear Usuario'}</Typography>
            <Grid sx={{
                marginTop: '5%'
            }}>
                <Typography variant='h3'>Datos del usuario</Typography>
                <Divider />
                <Box p={3}>
                    <Box mt={1.625}>
                        <Card variant='outlined'
                            sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)' }}>
                            <CardContent>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Nombre'
                                            name='nombre'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={user.nombre}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.nombre }
                                            helperText={ hasError.nombre ? 'Debe ingresar un nombre' : ''} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Apellido'
                                            name='apellido'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={user.apellido}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.apellido }
                                            helperText={ hasError.apellido ? 'Debe ingresar un apellido' : ''} />
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Nombre usuario'
                                            name='nombreUsuario'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={user.nombreUsuario}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.nombreUsuario }
                                            helperText={ hasError.nombreUsuario ? 'Debe ingresar un nombre de usuario' : ''} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='password'
                                            placeholder='Password'
                                            name='contrasena'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={user.contrasena}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.contrasena }
                                            helperText={ hasError.contrasena ? 'Debe ingresar un password' : ''} />
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-helper-label">Perfil</InputLabel>
                                            <Select value={user.idPerfil}
                                                label="Perfil"
                                                name='idPerfil'
                                                onChange={ changeInput }
                                                error={ hasError.idPerfil }>
                                                {profiles.map((profile) => (
                                                    <MenuItem value={profile.id}>{profile.nombre}</MenuItem>
                                                ))}
                                            </Select>
                                            {hasError.idPerfil ? <FormHelperText error={true}>Debe seleccionar un perfil</FormHelperText> : ''}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button variant='contained'
                                        color='primary'
                                        sx={{
                                            marginLeft: '75%',
                                            width: '25%'
                                        }}
                                        disabled={ isValidation }
                                        onClick={ actionClient }>
                                    {id !== '0' ? 'Modificar Usuario' : 'Crear Usuario'}
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Box>
            </Grid>
        </Container>
    )
}
