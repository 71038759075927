import { Button, Container, Grid, InputBase, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from "react";
import { Client } from "../../../models/client";
import { ClientService } from '../../../services/ClientService';
import { getDataUser } from '../../../storage/userStorage';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SweetAlert } from "../../../components/SweetAlert";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2e3192',
    color: 'white',
    fontSize: '1rem',
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.9rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const Clients = () => {
    const navigation = useNavigate();
    const clientService = ClientService();
    const sweetAlert = SweetAlert();
    const [clients, setClients] = useState<Client[]>([]);
    const [clientsFilter, setClientsFilter] = useState<Client[]>([]);

    const actionClient = (id?: string) => {
        if (id === undefined)
            navigation('/administracion/cliente/0');
        else
            navigation(`/administracion/cliente/${id}`);
    }

    const deleteClient = async (id: string, client: string) => {
        let result = await sweetAlert.showAlertWithButton(`el cliente ${client}`);
        if (result) {
            const response = await clientService.deleteClient(id, getDataUser().token);
            if (!response.isSuccess) {
                sweetAlert.showAlert(response.message, response.isSuccess)
            }
            else {
                sweetAlert.showAlert('El cliente se elimino correctamente', response.isSuccess);
                setClientsFilter(clients.filter(x => x.id !== id));
            }
        }
    }

    const searchClients = (event: any) => {
        if (event.target.value !== '') {
            const newData = clients.filter(function(item) {
                const itemNombre = item.nombre.toUpperCase();
                const itemApellido = item.apellido.toUpperCase();
                const itemDocumento = item.numeroDocumento.toUpperCase();
                const campo = `${itemNombre} ${itemApellido} ${itemDocumento}`;
                const textData = event.target.value.toUpperCase();
                return campo.indexOf(textData) > -1;
            });
            setClientsFilter(newData);
        } else {
            setClientsFilter(clients);
        }
    }

    useEffect(() => {
        const getClients = async () => {
            const data = await clientService.getAllCients(getDataUser().token);
            setClients(data.data);
            setClientsFilter(data.data);
        }
        getClients();
    }, [])

    return (
        <Container>
            <Typography variant="body2">Administracion/Clientes</Typography>
            <Button variant='contained'
                    color='primary'
                    sx={{
                        marginLeft: '80%'
                    }}
                    onClick={() => actionClient()}>
                Crear cliente
            </Button>
            <Grid sx={{
                marginTop: '5%'
            }}>
                <Paper component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto', marginBottom: '2rem' }} >
                    <InputBase sx={{ ml: 1, flex: 1 }}
                        placeholder="Buscar por nombre, apellido o usuario"
                        name='inputSearch'
                        onChange={ (event) => searchClients(event) }/>
                </Paper>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%', minHeight: '20%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Nombre</StyledTableCell>
                                <StyledTableCell>Apellido</StyledTableCell>
                                <StyledTableCell>Tipo Documento</StyledTableCell>
                                <StyledTableCell>Nro. Documento</StyledTableCell>
                                <StyledTableCell>Direccion</StyledTableCell>
                                <StyledTableCell>Telefono</StyledTableCell>
                                <StyledTableCell>Correo</StyledTableCell>
                                <StyledTableCell width='5%' align='right'></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clientsFilter?.map((client) => (
                                <StyledTableRow key={client.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {client.nombre}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {client.apellido}
                                    </StyledTableCell>
                                    <StyledTableCell>{client.tipoDocumento}</StyledTableCell>
                                    <StyledTableCell>{client.numeroDocumento}</StyledTableCell>
                                    <StyledTableCell>{client.direccion}</StyledTableCell>
                                    <StyledTableCell>{client.telefono}</StyledTableCell>
                                    <StyledTableCell>{client.correo}</StyledTableCell>
                                    <StyledTableCell>
                                        <Button onClick={() => actionClient(client.id)}>
                                            <ModeEditIcon />
                                        </Button>
                                        <Button onClick={() => deleteClient(client.id, client.nombre)}>
                                            <DeleteIcon />
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Container>
    )
}
