import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function PageLayout({ background, children }: any) {
  return (
    <Box
      width='100vw'
      height='100%'
      minHeight='100vh'
      sx={{ overflowX: 'hidden', background: background }}
    >
      {children}
    </Box>
  );
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: 'default',
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(['white', 'light', 'default']),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
