import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SweetAlert } from "./SweetAlert";

const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

export const AuthVerify = (props: any) => {
    let location = useLocation();
    const sweetAlert = SweetAlert();

    useEffect(() => {
        const token = localStorage.getItem("Token") || '';

        if (token) {
            const decodedJwt = parseJwt(token);

            if (decodedJwt.exp * 1000 < Date.now()) {
                sweetAlert.showAlert('Su sesion ha caducado', false)
                props.logOut();
            }
        }
    }, [location, props]);

    return (<></>);
}
