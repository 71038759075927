import { CustomResponse } from '../models';
import { Profile } from '../models/profile';
import api from './ConfigService';

export const ProfileService = () => {
    const getAllProfiles = async (token: string): Promise<CustomResponse<Profile[]>> => {
        let profiles: CustomResponse<Profile[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get('Perfiles', { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            profiles = response.data;
        }).catch(error => {
            profiles = error;
        });
        return profiles;
    }

    return {
        getAllProfiles
    }
}