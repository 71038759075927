import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

function Footer() {
  return (
    <Box component='footer' py={6}>
      <Grid container justifyContent='center'>
        <Grid item xs={12} lg={8} sx={{ textAlign: 'center' }}>
          <Typography variant='body1' color='secondary'>
            Derechos reservados &copy; 2023 Galivica Express.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
