import { CustomResponse } from '../models';
import { Client } from '../models/client';
import api from './ConfigService';

export const ClientService = () => {
    const getAllCients = async (token: string): Promise<CustomResponse<Client[]>> => {
        let clients: CustomResponse<Client[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get('Clientes', { headers: {
            'Authorization': `Bearer ${token}`
        } })
        .then(response => {
            clients = response.data;
        }).catch(error => {
            clients = error;
        });
        return clients;
    }

    const getClientById = async (id: string, token: string): Promise<CustomResponse<Client>> => {
        let client: CustomResponse<Client> = {} as CustomResponse<Client>;
        await api.get(`Clientes/${id}`, { headers: {
            'Authorization': `Bearer ${token}`
        } })
        .then(response => {
            client = response.data;
        }).catch(error => {
            client = error;
        });
        return client;
    }

    const getClientByTypeAndNumberDocument = async (documentType: string, numberDocument: string, token: string): Promise<CustomResponse<Client>> => {
        let client: CustomResponse<Client> = {} as CustomResponse<Client>;
        await api.get(`Clientes/${documentType}/${numberDocument}`, { headers: {
            'Authorization': `Bearer ${token}`
        } })
        .then(response => {
            client = response.data;
        }).catch(error => {
            client = error;
        });
        return client;
    }

    const createClient = async (clientRequest: Client, token: string): Promise<CustomResponse<Client>> => {
        let client: CustomResponse<Client> = {} as CustomResponse<Client>;
        await api.post('Clientes', clientRequest, { headers: {
            'Authorization': `Bearer ${token}`
        } })
        .then(response => {
            client = response.data;
        }).catch(error => {
            client = error;
        });
        return client;
    }

    const updateClient = async (clientRequest: Client, token: string): Promise<CustomResponse<Client>> => {
        let client: CustomResponse<Client> = {} as CustomResponse<Client>;
        await api.put('Clientes', clientRequest, { headers: {
            'Authorization': `Bearer ${token}`
        } })
        .then(response => {
            client = response.data;
        }).catch(error => {
            client = error;
        });
        return client;
    }

    const deleteClient = async (id: string, token: string): Promise<CustomResponse<boolean>> => {
        let flat: CustomResponse<boolean> = {} as CustomResponse<boolean>;
        await api.delete(`Clientes/${id}`, { headers: {
            'Authorization': `Bearer ${token}`
        } })
        .then(response => {
            flat = response.data;
        }).catch(error => {
            flat = error;
        });
        return flat;
    }

    return {
        getAllCients,
        getClientById,
        getClientByTypeAndNumberDocument,
        createClient,
        updateClient,
        deleteClient
    }
}