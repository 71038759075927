import { Button, Container, Grid, InputBase, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SweetAlert } from "../../../components/SweetAlert";
import { ServiceType } from "../../../models/serviceType";
import { ServiceTypeService } from "../../../services/ServiceTypeService";
import { getDataUser } from "../../../storage/userStorage";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2e3192',
    color: 'white',
    fontSize: '1rem',
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.9rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const ServiceTypes = () => {
    const navigation = useNavigate();
    const serviceTypeService = ServiceTypeService();
    const sweetAlert = SweetAlert();
    const [serviceType, setServiceType] = useState<ServiceType[]>([]);
    const [serviceTypeFilter, setServiceTypeFilter] = useState<ServiceType[]>([]);

    const actionServiceType = (id?: string) => {
        if (id === undefined)
            navigation('/administracion/tipo-servicio/0');
        else
            navigation(`/administracion/tipo-servicio/${id}`);
    }

    const deleteServiceType = async (id: string, description: string) => {
        let result = await sweetAlert.showAlertWithButton(`el servicio ${description}`);
        if (result) {
            const response = await serviceTypeService.deleteServiceType(id, getDataUser().token);
            if (!response.isSuccess) {
                sweetAlert.showAlert(response.message, response.isSuccess)
            }
            else {
                sweetAlert.showAlert('El tipo de servicio se elimino correctamente', response.isSuccess);
                setServiceTypeFilter(serviceType.filter(x => x.id !== id));
            }
        }
    }

    const searchServiceType = (event: any) => {
        if (event.target.value !== '') {
            const newData = serviceType.filter(function(item) {
                const itemData = item.descripcion.toUpperCase();
                const textData = event.target.value.toUpperCase();
                return itemData.indexOf(textData) > -1;
            });
            setServiceTypeFilter(newData);
        } else {
            setServiceTypeFilter(serviceType);
        }
    }

    useEffect(() => {
        const getServiceTypes = async () => {
            const data = await serviceTypeService.getAllServiceTypes(getDataUser().token);
            setServiceType(data.data);
            setServiceTypeFilter(data.data);
        }
        getServiceTypes();
    }, [])

    return (
        <Container>
            <Typography variant="body2">Tipos de  servicio</Typography>
            <Button variant='contained'
                    color='primary'
                    sx={{
                        marginLeft: '80%'
                    }}
                    onClick={() => actionServiceType()}>
                Crear tipo de servicio
            </Button>
            <Grid sx={{
                marginTop: '5%'
            }}>
                <Paper component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto', marginBottom: '2rem' }} >
                    <InputBase sx={{ ml: 1, flex: 1 }}
                        placeholder="Buscar por descripcion"
                        name='inputSearch'
                        onChange={ (event) => searchServiceType(event) }/>
                </Paper>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%', minHeight: '20%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Descripcion</StyledTableCell>
                                <StyledTableCell width='15%'>Valor Soles</StyledTableCell>
                                <StyledTableCell width='15%'>Valor Dolares</StyledTableCell>
                                <StyledTableCell width='15%'></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {serviceTypeFilter?.map((serviceType) => (
                                <StyledTableRow key={serviceType.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {serviceType.descripcion}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>{new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(serviceType.valorSoles)}</StyledTableCell>
                                    <StyledTableCell align='center'>{new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(serviceType.valorDolares)}</StyledTableCell>
                                    <StyledTableCell align='center'>
                                        <Button onClick={() => actionServiceType(serviceType.id)}>
                                            <ModeEditIcon />
                                        </Button>
                                        <Button onClick={() => deleteServiceType(serviceType.id, serviceType.descripcion)}>
                                            <DeleteIcon />
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                            <Typography></Typography>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Container>
    )
}
