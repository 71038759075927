import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDataUser } from '../../../storage/userStorage';
import { SweetAlert } from "../../../components/SweetAlert";
import { BillPay, BillPayDetail } from "../../../models/billPay";
import { BillPayService } from "../../../services/BillPayService";
import { Box, Button, Card, CardActions, CardContent, Container, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import BillPayRequest from "../../../models/billPay/BillPayRequest";
import { BillPayDetailService } from "../../../services/BillPayDetailService";


export const BillPayActions = () => {
    const navigation = useNavigate();
    const billPayService = BillPayService();
    const billPayDetailService = BillPayDetailService();
    const sweetAlert = SweetAlert();
    const [billPay, setBillPay] = useState<BillPay>({} as BillPay);
    const [billPayDetail, setBillPayDetail] = useState<BillPayDetail>({} as BillPayDetail);
    const [billPayRequest, setBillPayRequest] = useState<BillPayRequest>({} as BillPayRequest);
    const {id, idDetail} = useParams();
    const [hasError, setHasError] = useState({
        descripcion: false,
        fechaLimitePago: false,
        montoPagar: false,
        estatus: false
    });
    const [hasErrorDetail, setHasErrorDetail] = useState({
        fechaPago: false,
        montoPagado: false,
        formaPago: false,
        referencia: false
    });
    const [isValidation, setIsValidation] = useState(false);

    const changeInput = (event: any) => {
        const {name, value} = event.target;
        const regex = /^[0-9\,]*$/;
        if (name === 'montoPagar') {
            if (!regex.test(value)) {
                setBillPay({ ...billPay, [name]: billPay.montoPagar });
                sweetAlert.showAlert('El campo Monto Pagar solo acepta valores numericos', false);
                return;
            }
            setBillPay({ ...billPay, [name]: value })
            return;
        }
        setBillPay({ ...billPay, [name]: value });
    }

    const changeInputDetail = (event: any) => {
        const {name, value} = event.target;
        const regex = /^[0-9\,]*$/;
        if (name === 'montoPagado') {
            if (!regex.test(value)) {
                setBillPayDetail({ ...billPayDetail, [name]: billPayDetail.montoPagado });
                sweetAlert.showAlert('El campo Monto Pagado solo acepta valores numericos', false);
                return;
            }
            setBillPayDetail({ ...billPayDetail, [name]: value })
            return;
        }
        setBillPayDetail({ ...billPayDetail, [name]: value });
    }

    const changeDate = (newValue: any) => {
        var date = new Date();
        date.setDate(newValue.$D);
        date.setMonth(newValue.$M)
        date.setFullYear(newValue.$y);
        setBillPay({...billPay, fechaLimitePago: date});
    }

    const changePayDate = (newValue: any) => {
        var date = new Date();
        date.setDate(newValue.$D);
        date.setMonth(newValue.$M)
        date.setFullYear(newValue.$y);
        setBillPayDetail({...billPayDetail, fechaPago: date});
    }

    const validateField = async () => {
        if (id === '0' && (billPay.descripcion === undefined || billPay.fechaLimitePago === undefined ||
            billPay.montoPagar === undefined)) {
            setHasError({
                ...hasError,
                descripcion: billPay.descripcion === undefined,
                fechaLimitePago: billPay.fechaLimitePago === undefined,
                montoPagar: billPay.montoPagar === undefined,
                estatus: billPay.estatus === undefined
            });
            return true;
        }
        if (id !== '0' && (billPayDetail.fechaPago === undefined || billPayDetail.montoPagado === undefined ||
            billPayDetail.formaPago === undefined || billPayDetail.referencia === undefined)) {
            setHasErrorDetail({
                ...hasError,
                fechaPago: billPayDetail.fechaPago === undefined,
                montoPagado: billPayDetail.montoPagado === undefined,
                formaPago: billPayDetail.formaPago === undefined,
                referencia: billPayDetail.referencia === undefined
            });
            transactionValidate();
            return true;
        }
        return false;
    }

    const transactionValidate = (): boolean => {
        if (billPay.estatus === 'Abonado' && billPayDetail.montoPagado ? billPayDetail.montoPagado >= billPay.montoPagar : false) {
            sweetAlert.showAlert('El campo Monto Pagado no puede ser mayor o igual al Monto a Pagar', false);
            return true;
        }
        if (billPay.estatus === 'Pagado' && billPayDetail.montoPagado ? billPayDetail.montoPagado < billPay.montoPagar : false) {
            sweetAlert.showAlert('El campo Monto Pagado no puede ser menor al Monto a Pagar', false);
            return true;
        }
        return false;
    }

    const actionBillPay = async () => {
        setIsValidation(true);
        if (await validateField()) {
            setIsValidation(false);
            return;
        }
        if (id === '0') {
            billPay.estatus = 'Pendiente';
            billPay.montoPagar = parseFloat(billPay.montoPagar.toString().replace(',', '.'));
            billPay.saldo = billPay.montoPagar;
        } else {
            if (transactionValidate()) return;
            billPayRequest.estatus = billPay.estatus;
            billPayRequest.id = billPay.id;
            billPayRequest.referencia = billPayDetail.referencia;
            billPayRequest.montoPagado = parseFloat(billPayDetail.montoPagado.toString().replace(',', '.'));
            billPayRequest.saldo = billPayDetail.montoPagado ? billPay.saldo - billPayRequest.montoPagado : billPay.saldo;
            billPayRequest.formaPago = billPayDetail.formaPago;
            billPayRequest.fechaPago = billPayDetail.fechaPago;
            billPayRequest.estatus = billPay.estatus;
            billPayRequest.idDetalle = idDetail !== '0' ? idDetail : undefined;
        }
        const response = id !== '0' ? await billPayService.updateBillPay(billPayRequest, getDataUser().token)
                        : await billPayService.createBillPay(billPay, getDataUser().token);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess)
            setIsValidation(false);
        }
        else {
            sweetAlert.showAlert(id !== '0' ? 'La cuenta por pagar se modifico correctamente' : 'La cuenta por pagar se creo correctamente', response.isSuccess);
            navigation('/cuentas-pagar');
            setIsValidation(false);
        }
    }

    useEffect(() => {
        const getBillPay = async (id: any) => {
            const data = await billPayService.getBillPayById(id, getDataUser().token);
            setBillPay(data.data);
        }
        const getBillPayDetail = async (id: any) => {
            const data = await billPayDetailService.getBillPayDetailById(id, getDataUser().token);
            setBillPayDetail(data.data);
        }
        if (id !== '0') getBillPay(id);
        if (idDetail !== '0') getBillPayDetail(idDetail);
    }, [])

    return (
        <Container>
            <Typography variant="body2">Cuentas por Pagar/{id !== '0' ? 'Modificar cuenta por pagar' : 'Crear cuenta por pagar'}</Typography>
            <Grid sx={{
                marginTop: '5%'
            }}>
                <Typography variant='h3'>Datos de la cuenta</Typography>
                <Divider />
                <Box p={3}>
                    <Box mt={1.625}>
                        <Card variant='outlined'
                            sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)' }}>
                            <CardContent>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={12}>
                                        {id === '0' ?
                                            <TextField type='text'
                                                placeholder='Descripcion'
                                                name='descripcion'
                                                fullWidth
                                                required
                                                variant='outlined'
                                                value={billPay.descripcion}
                                                onChange={ changeInput }
                                                disabled={ isValidation }
                                                error={ hasError.descripcion }
                                                helperText={ hasError.descripcion ? 'Debe ingresar una descripcion' : ''} /> :
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>{billPay.descripcion}</Typography>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        {id === '0' ?
                                            <TextField type='text'
                                                placeholder='Monto a pagar'
                                                name='montoPagar'
                                                fullWidth
                                                required
                                                variant='outlined'
                                                value={billPay.montoPagar}
                                                onChange={ changeInput }
                                                disabled={ isValidation }
                                                error={ hasError.montoPagar }
                                                helperText={ hasError.montoPagar ? 'Debe ingresar un monto a pagar' : ''} /> :
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-helper-label">Estatus</InputLabel>
                                                <Select value={billPay.estatus}
                                                    label="Estatus"
                                                    name='estatus'
                                                    onChange={ changeInput }
                                                    error={ hasError.estatus }>
                                                    <MenuItem value='Abonado'>Abonado</MenuItem>
                                                    <MenuItem value='Pagado'>Pagado</MenuItem>
                                                </Select>
                                                {hasError.estatus ? <FormHelperText error={true}>Debe seleccionar un estatus</FormHelperText> : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {id === '0' ?
                                            <DatePicker label="Fecha Limite de Pago"
                                                slotProps={{ textField: { fullWidth: true } }}
                                                onChange={ (newValue) => changeDate(newValue) }
                                                value={dayjs(billPay.fechaLimitePago)}
                                                format='DD/MM/YYYY' /> :
                                            <TextField type='text'
                                                placeholder='Monto pagado'
                                                name='montoPagado'
                                                fullWidth
                                                required
                                                variant='outlined'
                                                value={billPayDetail.montoPagado}
                                                onChange={ changeInputDetail }
                                                disabled={ isValidation }
                                                error={ hasErrorDetail.montoPagado }
                                                helperText={ hasErrorDetail.montoPagado ? 'Debe ingresar el monto que se pago' : ''} />
                                        }
                                    </Grid>
                                    {id !== '0' ?
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-helper-label">Forma de Pago</InputLabel>
                                                    <Select value={billPayDetail.formaPago}
                                                        label="Forma de Pago"
                                                        name='formaPago'
                                                        onChange={ changeInputDetail }
                                                        error={ hasErrorDetail.formaPago }>
                                                        <MenuItem value='Efectivo'>Efectivo</MenuItem>
                                                        <MenuItem value='Transferencia'>Transferencia</MenuItem>
                                                        <MenuItem value='Yape'>Yape</MenuItem>
                                                        <MenuItem value='Dolares'>Dolares</MenuItem>
                                                        <MenuItem value='Contraentrega'>Contraentrega</MenuItem>
                                                        <MenuItem value='Zelle'>Zelle</MenuItem>
                                                    </Select>
                                                    {hasErrorDetail.formaPago ? <FormHelperText error={true}>Debe seleccionar una forma de pago</FormHelperText> : ''}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField type='text'
                                                    placeholder='Referencia'
                                                    name='referencia'
                                                    fullWidth
                                                    required
                                                    variant='outlined'
                                                    value={billPayDetail.referencia}
                                                    onChange={ changeInputDetail }
                                                    disabled={ isValidation }
                                                    error={ hasErrorDetail.referencia }
                                                    helperText={ hasErrorDetail.referencia ? 'Debe ingresar una referencia' : ''} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <DatePicker label="Fecha de Pago"
                                                    slotProps={{ textField: { fullWidth: true } }}
                                                    onChange={ (newValue) => changePayDate(newValue) }
                                                    value={dayjs(billPayDetail.fechaPago)}
                                                    format='DD/MM/YYYY' />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold', paddingTop: '1rem' }}>Saldo:</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold', paddingTop: '1rem' }}>
                                                    $ {new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(billPay.saldo)}
                                                </Typography>
                                            </Grid>
                                        </>
                                        : <></>
                                    }
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button variant='contained'
                                        color='primary'
                                        sx={{
                                            marginLeft: '75%',
                                            width: '25%'
                                        }}
                                        disabled={ isValidation }
                                        onClick={ actionBillPay }>
                                    {id !== '0' ? 'Modificar Cuenta por Pagar' : 'Crear Cuenta por Pagar'}
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Box>
            </Grid>
        </Container>
    )
}
