import Swal from "sweetalert2";

export const SweetAlert = () => {
    const showAlert = (title: string, action: boolean) =>
        Swal.fire(`${title}`, '', action ? 'success' : 'error');

    const showAlertWithButton = async (description: string) => {
        let response: boolean = false;
        const newSwal = Swal.mixin({
          buttonsStyling: true,
        });
        await newSwal
            .fire({
                title: 'Estas seguro de eliminar?',
                text: `Si confirmas estaras eliminando ${description}!`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#2e3192',
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                reverseButtons: true
            })
            .then((result) => {
                if (result.value) {
                    response = true;
                } else {
                    response = false;
                }                    
            });
        return response;
    };

    return {
        showAlert,
        showAlertWithButton
    }
}
