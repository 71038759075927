import { useEffect, useState } from 'react';
import { getDataUser } from '../../storage/userStorage';
import { User } from '../../models/user';
import { Container, Grid, Typography } from '@mui/material';
import logo from '../../assets/images/galivica.png';

export const Home = () => {
    let fecha = new Date();
    let dia = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'];
    let mes = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const [hoy, setHoy] = useState('');
    const [user, setUser] = useState<User>();

    useEffect(() => {
        setUser(getDataUser());
        setHoy(dia[fecha.getDay()] + ', ' + fecha.getDate() + ' de ' + mes[fecha.getMonth()] + ' de ' + fecha.getFullYear());
    }, []);
    
    return (
        <Container>
            <Typography variant='body1'
                textAlign='right'>{hoy}</Typography>
            <Grid>
                <Container sx={{
                    marginTop: '5%',
                    marginLeft: '20%',
                    marginBottom: '5%'
                }}>
                    <img src={logo}
                        alt='Logo'
                        width='50%'/>
                </Container>
                <Typography variant='h2'
                    textAlign='center'>
                    Bienvenid@ {user?.nombre} {user?.apellido}
                </Typography>
                <Typography variant='h3'
                    textAlign='center'>
                    Sistema de trackeo y monitoreo de paquetes
                </Typography>
            </Grid>
        </Container>
    )
}
