import { CustomResponse } from '../models';
import { DailyCashBalance } from '../models/dailyCashBalance/DailyCashBalance';
import api from './ConfigService';

export const DailyCashBalanceService = () => {
    const getDailyCashBalance = async (date: string, token: string): Promise<CustomResponse<DailyCashBalance[]>> => {
        let dailyCashBalance: CustomResponse<DailyCashBalance[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get(`CuadreCajaDiario/${date}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            dailyCashBalance = response.data;
        }).catch(error => {
            dailyCashBalance = error;
        });
        return dailyCashBalance;
    }

    return {
        getDailyCashBalance
    }
}