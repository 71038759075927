import { CustomResponse } from '../models';
import { RateDay } from '../models/rateDay';
import api from './ConfigService';

export const RateDayService = () => {
    const getRateDay = async (token: string): Promise<CustomResponse<RateDay>> => {
        let rateDay: CustomResponse<RateDay> = {} as CustomResponse<RateDay>;
        await api.get(`TasaDia`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            rateDay = response.data;
        }).catch(error => {
            rateDay = error;
        });
        return rateDay;
    }

    const updateRateDay = async (rateDayRequest: RateDay, token: string): Promise<CustomResponse<RateDay>> => {
        let rateDay: CustomResponse<RateDay> = {} as CustomResponse<RateDay>;
        await api.put('TasaDia', rateDayRequest, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            rateDay = response.data;
        }).catch(error => {
            rateDay = error;
        });
        return rateDay;
    }

    return {
        getRateDay,
        updateRateDay
    }
}