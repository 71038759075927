import { CustomResponse } from '../models';
import { Tracking } from '../models/tracking';
import { TrackingRequestList } from '../models/tracking/TrackingRequestList';
import api from './ConfigService';

export const TrackingService = () => {
    const getAllTracking = async (token: string): Promise<CustomResponse<Tracking[]>> => {
        let tracking: CustomResponse<Tracking[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get('Tracking', { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            tracking = response.data;
        }).catch(error => {
            tracking = error;
        });
        return tracking;
    }

    const getTrackingByDate = async (date: string, token: string): Promise<CustomResponse<Tracking[]>> => {
        let tracking: CustomResponse<Tracking[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get(`Tracking/PorFecha/${date}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            tracking = response.data;
        }).catch(error => {
            tracking = error;
        });
        return tracking;
    }

    const getTrackingByRange = async (dateStart: string, dateEnd: string, token: string): Promise<CustomResponse<Tracking[]>> => {
        let tracking: CustomResponse<Tracking[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get(`Tracking/PorRango/${dateStart}/${dateEnd}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            tracking = response.data;
        }).catch(error => {
            tracking = error;
        });
        return tracking;
    }

    const getTrackingById = async (id: string, token: string): Promise<CustomResponse<Tracking>> => {
        let tracking: CustomResponse<Tracking> = {} as CustomResponse<Tracking>;
        await api.get(`Tracking/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            tracking = response.data;
        }).catch(error => {
            tracking = error;
        });
        return tracking;
    }

    const createTracking = async (tracking: Tracking, token: string): Promise<CustomResponse<Tracking>> => {
        let trackings: CustomResponse<Tracking> = {} as CustomResponse<Tracking>;
        await api.post('Tracking', tracking, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            trackings = response.data;
        }).catch(error => {
            trackings = error;
        });
        return trackings;
    }

    const updateTracking = async (trackings: TrackingRequestList, token: string): Promise<CustomResponse<boolean>> => {
        let flat: CustomResponse<boolean> = {} as CustomResponse<boolean>;
        await api.put('Tracking', trackings, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            flat = response.data;
        }).catch(error => {
            flat = error;
        });
        return flat;
    }

    const deleteTracking = async (id: string, token: string): Promise<CustomResponse<boolean>> => {
        let flat: CustomResponse<boolean> = {} as CustomResponse<boolean>;
        await api.delete(`Tracking/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            flat = response.data;
        }).catch(error => {
            flat = error;
        });
        return flat;
    }

    return {
        getAllTracking,
        getTrackingByDate,
        getTrackingByRange,
        getTrackingById,
        createTracking,
        updateTracking,
        deleteTracking
    }
}