import { Box, Button, Card, CardActions, CardContent, Container, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SweetAlert } from "../../../components/SweetAlert";
import { ExpensePurchaseControl } from "../../../models/expensePurchaseControl";
import { ExpensePurchaseControlService } from "../../../services/ExpensePurchaseControlService";
import { getDataUser } from "../../../storage/userStorage";

export const ExpensePurchaseControlAction = () => {
    const navigation = useNavigate();
    const expensePurchaseControlService = ExpensePurchaseControlService();
    const sweetAlert = SweetAlert();
    const [expensePurchaseControl, setExpensePurchaseControl] = useState<ExpensePurchaseControl>({} as ExpensePurchaseControl);
    const {id} = useParams();
    const [hasError, setHasError] = useState({
        descripcion: false,
        referencia: false,
        valor: false,
        moneda: false
    });
    const [isValidation, setIsValidation] = useState(false);

    const changeInput = (event: any) => {
        const {name, value} = event.target;
        const regex = /^[0-9\,]*$/;
        if (name === 'valor') {
            if (!regex.test(value)) {
                setExpensePurchaseControl({ ...expensePurchaseControl, [name]: expensePurchaseControl.valor });
                sweetAlert.showAlert('El campo Monto solo acepta valores numericos', false);
                return;
            }
            setExpensePurchaseControl({ ...expensePurchaseControl, [name]: value })
            return;
        }
        setExpensePurchaseControl({ ...expensePurchaseControl, [name]: value });
    }

    const validateField = async () => {
        if (expensePurchaseControl.descripcion === undefined || expensePurchaseControl.referencia === undefined ||
            expensePurchaseControl.valor === undefined || expensePurchaseControl.moneda === undefined) {
            setHasError({
                ...hasError,
                descripcion: expensePurchaseControl.descripcion === undefined,
                referencia: expensePurchaseControl.referencia === undefined,
                valor: expensePurchaseControl.valor === undefined,
                moneda: expensePurchaseControl.moneda === undefined
            });
            return true;
        }
        return false;
    }

    const actionExpensePurchaseControl = async () => {
        setIsValidation(true);
        if (await validateField()) {
            setIsValidation(false);
            return;
        }
        expensePurchaseControl.valor = parseFloat(expensePurchaseControl.valor.toString().replace(',', '.'));
        const response = id !== '0' ? await expensePurchaseControlService.updateExpensePurchaseControl(expensePurchaseControl, getDataUser().token)
                        : await expensePurchaseControlService.createExpensePurchaseControl(expensePurchaseControl, getDataUser().token);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess)
            setIsValidation(false);
        }
        else {
            sweetAlert.showAlert(id !== '0' ? 'El control de gasto y compra se modifico correctamente' : 'El control de gasto y compra se creo correctamente', response.isSuccess);
            navigation('/control-gastos');
            setIsValidation(false);
        }
    }

    useEffect(() => {
        const getExpensePurchaseControl = async (id: any) => {
            const data = await expensePurchaseControlService.getExpensePurchaseControlById(id, getDataUser().token);
            setExpensePurchaseControl(data.data);
        }
        if (id !== '0') getExpensePurchaseControl(id);
    }, [])
    
    return (
        <Container>
            <Typography variant="body2">Control de Gastos y Compras/{id !== '0' ? 'Modificar control de gasto y compra' : 'Crear control de gasto y compra'}</Typography>
            <Grid sx={{
                marginTop: '5%'
            }}>
                <Typography variant='h3'>Datos del gasto o compra</Typography>
                <Divider />
                <Box p={3}>
                    <Box mt={1.625}>
                        <Card variant='outlined'
                            sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)' }}>
                            <CardContent>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Descripcion'
                                            name='descripcion'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={expensePurchaseControl.descripcion}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.descripcion }
                                            helperText={ hasError.descripcion ? 'Debe ingresar una descripcion' : ''} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Referencia'
                                            name='referencia'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={expensePurchaseControl.referencia}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.referencia }
                                            helperText={ hasError.referencia ? 'Debe ingresar una referencia' : ''} />
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Monto'
                                            name='valor'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={expensePurchaseControl.valor !== undefined ? expensePurchaseControl.valor.toString().replace('.', ',') : ''}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.valor }
                                            helperText={ hasError.valor ? 'Debe ingresar un monto' : ''} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-helper-label">Moneda</InputLabel>
                                            <Select value={expensePurchaseControl.moneda}
                                                defaultValue={expensePurchaseControl.moneda}
                                                label="Moneda"
                                                name='moneda'
                                                onChange={ changeInput }
                                                error={ hasError.moneda }>
                                                <MenuItem value='Soles'>Soles</MenuItem>
                                                <MenuItem value='Dolares'>Dolares</MenuItem>
                                            </Select>
                                            {hasError.moneda ? <FormHelperText error={true}>Debe seleccionar una moneda</FormHelperText> : ''}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button variant='contained'
                                        color='primary'
                                        sx={{
                                            marginLeft: '75%',
                                            width: '25%'
                                        }}
                                        disabled={ isValidation }
                                        onClick={ actionExpensePurchaseControl }>
                                    {id !== '0' ? 'Modificar Gasto o Compra' : 'Crear Gasto o Compra'}
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Box>
            </Grid>
        </Container>
    )
}
