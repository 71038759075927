import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../../models/user';

const initialState: User = {
  id: '',
  nombre: '',
  apellido: '',
  nombreUsuario: '',
  contrasena: '',
  idPerfil: '',
  perfil: '',
  token: ''
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    increment: (state) => {
    
    },
    decrement: (state) => {
      
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = userSlice.actions