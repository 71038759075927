import { useState } from 'react';
import { Tracking, TrackingRequest } from '../../../models/tracking';
import { styled } from '@mui/material/styles';
import { Button, Card, CardContent, Container, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { SweetAlert } from '../../../components/SweetAlert';
import { TrackingService } from '../../../services/TrackingService';
import { getDataUser } from '../../../storage/userStorage';
import { TrackingRequestList } from '../../../models/tracking/TrackingRequestList';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2e3192',
      color: 'white',
      fontSize: '1rem',
      textAlign: 'center'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '0.9rem',
    },
  }));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const FollowUp = () => {
    const trackingService = TrackingService();
    const sweetAlert = SweetAlert();
    const [tracking, setTracking] = useState<Tracking[]>([]);
    const [trackingRequests, setTrackingRequests] = useState<TrackingRequest[]>([]);
    const [trackingRequest, setTrackingRequest] = useState<TrackingRequest>({} as TrackingRequest);
    const [trackingRequestList, setTrackingRequestList] = useState<TrackingRequestList>({} as TrackingRequestList);
    const [estatus, setEstatus] = useState<string>('');
    const [dateFilter, setDateFilter] = useState({
        fecha: new Date()
    });
    const [hasError, setHasError] = useState({
        estatus: false
    });
    const [enableButton, setEnableButton] = useState<boolean>(true);

    const changeInput = (event: any) => {
        const {name, value} = event.target;
        setEstatus(value);
        setEnableButton(tracking.length === 0);
    }

    const changeStatus = async () => {
        setEnableButton(true);
        fillObjectTrackingRequest(tracking);
        const response = await trackingService.updateTracking(trackingRequestList, getDataUser().token);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess)
            setEnableButton(false);
        } else {
            sweetAlert.showAlert('El estatus se actualizo correctamente', response.isSuccess);
            setTracking([]);
            setDateFilter({...dateFilter, fecha: new Date()})
            setEnableButton(true);
        }
    }

    const fillObjectTrackingRequest = (trackings: Tracking[]) => {
        trackings.map((t) => {
            trackingRequests.push({id: t.id, estatus: estatus});
        });
        trackingRequestList.trackings = trackingRequests;
    }

    const changeDate = async (newValue: any) => {
        if (newValue.$W !== 6) {
            sweetAlert.showAlert('El dia seleccionado debe ser sabado', false);
            return;
        }
        setTracking([]);
        var date = new Date();
        date.setDate(newValue.$D);
        date.setMonth(newValue.$M)
        date.setFullYear(newValue.$y);
        setDateFilter({...dateFilter, fecha: date});
        const response = await trackingService.getTrackingByDate(date.toLocaleDateString('en-US').replaceAll('/', '-'), getDataUser().token);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess)
            setEnableButton(true);
        }
        setTracking(response.data);
    }
    
    return (
        <Container>
            <Typography variant="body2">Seguimiento</Typography>
            <Grid sx={{
                marginTop: '2%'
            }}>
                <Card variant='outlined'
                    sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)', marginBottom: '2rem' }}>
                    <CardContent>
                        <Grid container
                            sx={{ marginTop: '1rem' }}>
                            <Grid item xs={12} sm={2}
                                sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                                Fecha
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <DatePicker slotProps={{ textField: { fullWidth: true } }}
                                    onChange={ (newValue) => changeDate(newValue) }
                                    value={dayjs(dateFilter.fecha)}
                                    format='DD/MM/YYYY' />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid container sx={{ marginTop: '2%', marginBottom: '2%' }}>
                    <Grid item xs={12} sm={2}>
                        <Typography variant='body1' sx={{ paddingTop: '8%', paddingLeft: '2%' }}>Seleccione Nuevo Estatus:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-helper-label">Estatus</InputLabel>
                            <Select value={estatus}
                                label="Seleccionar Estatus"
                                name='estatus'
                                onChange={ changeInput }
                                error={ hasError.estatus }>
                                    <MenuItem value='Recibido'>Recibido</MenuItem>
                                    <MenuItem value='Empacado'>Empacado</MenuItem>
                                    <MenuItem value='Aduana'>Aduana</MenuItem>
                                    <MenuItem value='Embarcado'>Embarcado</MenuItem>
                                    <MenuItem value='Arribo'>Arribo</MenuItem>
                                    <MenuItem value='Recibido en logistica'>Recibido en logistica</MenuItem>
                                    <MenuItem value='Transferido'>Transferido</MenuItem>
                                    <MenuItem value='Distribucion'>Distribucion</MenuItem>
                                    <MenuItem value='Entregado'>Entregado</MenuItem>
                            </Select>
                            {hasError.estatus ? <FormHelperText error={true}>Debe seleccionar un estatus</FormHelperText> : ''}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button variant='contained'
                            color='primary'
                            onClick={() => changeStatus()}
                            disabled={enableButton}
                            sx={{ paddingTop: '5%', paddingBottom: '5%', marginLeft: '5%', width: '95%' }}>
                            Cambiar Estatus
                        </Button>
                    </Grid>
                </Grid>       
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%', minHeight: '20%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Numero Control</StyledTableCell>
                                <StyledTableCell>Medidas</StyledTableCell>
                                <StyledTableCell>Almacen Origen</StyledTableCell>
                                <StyledTableCell>Almacen Destino</StyledTableCell>
                                <StyledTableCell>Caja Numero</StyledTableCell>
                                <StyledTableCell>Peso Facturado</StyledTableCell>
                                <StyledTableCell>Total a Cobrar en Soles</StyledTableCell>
                                <StyledTableCell>Total a Cobrar en Dolares</StyledTableCell>
                                <StyledTableCell>Estatus</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tracking?.map((t) => (
                                <StyledTableRow key={t.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {t.numeroControl}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>{t.medidas}</StyledTableCell>
                                    <StyledTableCell align='center'>{t.almacenOrigen}</StyledTableCell>
                                    <StyledTableCell align='center'>{t.almacenDestino}</StyledTableCell>
                                    <StyledTableCell align='center'>{t.cajaNumero}</StyledTableCell>
                                    <StyledTableCell align='center'>{t.pesoFacturado} {t.tipoPeso}</StyledTableCell>
                                    <StyledTableCell align='center'>{new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(t.totalCobrarSoles)}</StyledTableCell>
                                    <StyledTableCell align='center'>{new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(t.totalCobrarDolares)}</StyledTableCell>
                                    <StyledTableCell align='center'>{t.estatus}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Container>
    )
}
