import { Box, Button, Divider, FormControl, Grid, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import { ClientService } from "../../../services/ClientService";
import { getDataUser } from "../../../storage/userStorage";
import { SweetAlert } from "../../../components/SweetAlert";

export const StepAddressee = ({data, setData, handleStep}: any) => {
    const sweetAlert = SweetAlert();
    const clientService = ClientService();
    const [dataSearch, setDataSearch] = useState({
        tipoDocumento: '',
        numeroDocumento: ''
    });
    const [validData, setValidData] = useState<number>();
    const [noClient, setNoClient] = useState<boolean>(false);

    const changeInput = (event: any) => {
        const {name, value} = event.target;
        setDataSearch({...dataSearch, [name]: value})
    }

    const searchClient = async () => {
        if (dataSearch.tipoDocumento === '' || dataSearch.numeroDocumento === '') {
            sweetAlert.showAlert('Para poder buscar un cliente debe seleccionar un tipo de documento y un numero de documento', false)
            return;
        }
        const data = await clientService.getClientByTypeAndNumberDocument(dataSearch.tipoDocumento, dataSearch.numeroDocumento, getDataUser().token);
        setNoClient(data.isSuccess ? false : true);
        setData(data.data);
        handleStep(!data.isSuccess);
    }
    
    useEffect(() => {
        setValidData(data === null ? 0 : Object.entries(data).length)
    }, [data]);
    
    return (
        <>
            <Paper component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto', marginBottom: '2rem' }} >
                <FormControl sx={{ width: '15%' }}>
                    <InputLabel id="demo-simple-select-helper-label">Tipo Documento</InputLabel>
                    <Select label="Tipo Documento"
                        name='tipoDocumento'
                        onChange={ changeInput }>
                            <MenuItem value='DNI'>DNI</MenuItem>
                            <MenuItem value='CPP'>CPP</MenuItem>
                            <MenuItem value='PTP'>PTP</MenuItem>
                            <MenuItem value='CE'>CE</MenuItem>
                            <MenuItem value='PAS'>PAS</MenuItem>
                            <MenuItem value='VE'>VE</MenuItem>
                    </Select>
                </FormControl>
                <InputBase name="numeroDocumento"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Buscar destinatario por numero de documento"
                    inputProps={{ 'aria-label': 'search google maps' }}
                    onChange={ changeInput }/>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <Button
                    onClick={ searchClient }>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" >
                        <SearchIcon />
                    </IconButton>
                </Button>
            </Paper>
            <Box sx ={{ padding: '1rem' }}>
                {validData !== 0 ?
                    <>
                        <Grid container
                            spacing={3}
                            sx={{ marginBottom: '2%' }}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body1">Nombre y Apellido: <strong>{data.nombre} {data.apellido}</strong></Typography>
                            </Grid>
                        </Grid>
                        <Grid container
                            spacing={3}
                            sx={{ marginBottom: '2%' }}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body1">Nro. Documento: <strong>{data.numeroDocumento}</strong></Typography>
                            </Grid>
                        </Grid>
                        <Grid container
                            spacing={3}
                            sx={{ marginBottom: '2%' }}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body1">Correo electronico: <strong>{data.correo}</strong></Typography>
                            </Grid>
                        </Grid>
                        <Grid container
                            spacing={3}
                            sx={{ marginBottom: '2%' }}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body1">Telefono: <strong>{data.telefono}</strong></Typography>
                            </Grid>
                        </Grid>
                        <Grid container
                            spacing={3}
                            sx={{ marginBottom: '2%' }}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body1">Direccion: <strong>{data.direccion}</strong></Typography>
                            </Grid>
                        </Grid>
                    </>
                    : noClient ?
                    <Typography variant='body1'>No se encontro cliente por el tipo y/o numero de documento ingresado</Typography>
                    : ''
                }
            </Box>
        </>
    )
}
