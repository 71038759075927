import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { BillPay, BillPayDetail } from '../../../models/billPay';
import { Card, CardContent, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { BillPayDetailService } from '../../../services/BillPayDetailService';
import { getDataUser } from '../../../storage/userStorage';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SweetAlert } from "../../../components/SweetAlert";
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    },
}));

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2e3192',
      color: 'white',
      fontSize: '1rem',
      textAlign: 'center'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '0.9rem',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export default function DialogDetail({data, open, onClose}: any) {
    const navigation = useNavigate();
    const billPayDetailService = BillPayDetailService();
    const [billPay, setBillPay] = useState<BillPay>({} as BillPay);
    const [billPayDetails, setBillPayDetails] = useState<BillPayDetail[]>([]);
    const sweetAlert = SweetAlert();
    
    const handleClose = () => {
        onClose();
    };

    const updateBillPayDetail = (id: string) => {
        navigation(`/cuenta-pagar/${billPay.id}/${id}`);
    }

    const deleteBillPayDetail = async (id: string) => {
        let result = await sweetAlert.showAlertWithButton(`el detalle de la cuenta`);
        if (result) {
            const response = await billPayDetailService.deleteBillPayDetails(id, getDataUser().token);
            if (!response.isSuccess) {
                sweetAlert.showAlert(response.message, response.isSuccess)
            }
            else {
                sweetAlert.showAlert('La cuenta por pagar se elimino correctamente', response.isSuccess);
                setBillPayDetails(billPayDetails.filter(x => x.id !== id));
            }
        }
    }

    useEffect(() => {
        setBillPayDetails([]);
        setBillPay(data);
        const getBillPayDetails = async () => {
            const response = await billPayDetailService.getAllBillPayDetails(getDataUser().token, data.id);
            setBillPayDetails(response.data);
        }
        getBillPayDetails();
    }, [data])

    return (
        <BootstrapDialog aria-labelledby="customized-dialog-title"
            open={open}>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Detalles de la cuenta
            </DialogTitle>
            <DialogContent dividers>
                <Card variant='outlined'
                    sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)' }}>
                    <CardContent>
                        <Grid container
                            sx={{ marginTop: '1rem' }}>
                            <Grid item xs={12} sm={6}>
                                Descripcion: <strong>{billPay.descripcion}</strong>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                Estatus: <strong>{billPay.estatus}</strong>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                Monto a pagar: <strong>${billPay.montoPagar}</strong>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                Saldo pendiente: <strong>${billPay.saldo}</strong>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                Fecha limite de pago: <strong>{new Date(billPay.fechaLimitePago).toLocaleDateString('en-GB')}</strong>
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: '100%', minHeight: '20%', marginTop: '1rem' }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell width='15%'>Forma de Pago</StyledTableCell>
                                        <StyledTableCell width='15%'>Referencia</StyledTableCell>
                                        <StyledTableCell width='15%'>Fecha de Pago</StyledTableCell>
                                        <StyledTableCell width='15%'>Monto Pagado</StyledTableCell>
                                        <StyledTableCell width='15%' align='right'></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {billPayDetails?.map((billPay) => (
                                        <StyledTableRow key={billPay.id}>
                                            <StyledTableCell component="th" scope="row">
                                                {billPay.formaPago}
                                            </StyledTableCell>
                                            <StyledTableCell align='center'>{billPay.referencia}</StyledTableCell>
                                            <StyledTableCell align='center'>
                                                {new Date(billPay.fechaPago).toLocaleDateString('en-GB')}
                                            </StyledTableCell>
                                            <StyledTableCell align='center'>{new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(billPay.montoPagado)}</StyledTableCell>
                                            <StyledTableCell align='center'>
                                                <Grid container>
                                                    <Grid item xs={12} sm={6}>
                                                        <Button onClick={() => updateBillPayDetail(billPay.id)}>
                                                            <ModeEditIcon />
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Button onClick={() => deleteBillPayDetail(billPay.id)}>
                                                            <DeleteIcon />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cerrar detalle
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}