import { CustomResponse } from '../models';
import { ServiceType } from '../models/serviceType';
import { ServiceTypeRequest } from '../models/serviceType/ServiceTypeRequest';
import api from './ConfigService';

export const ServiceTypeService = () => {
    const getAllServiceTypes = async (token: string): Promise<CustomResponse<ServiceType[]>> => {
        let serviceTypes: CustomResponse<ServiceType[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get('TipoServicios', { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            serviceTypes = response.data;
        }).catch(error => {
            serviceTypes = error;
        });
        return serviceTypes;
    }

    const getServiceTypesByDescription = async (description: string, token: string): Promise<CustomResponse<ServiceType[]>> => {
        let serviceTypes: CustomResponse<ServiceType[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get(`TipoServicios/PorDescripcion/${description}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            serviceTypes = response.data;
        }).catch(error => {
            serviceTypes = error;
        });
        return serviceTypes;
    }

    const getServiceTypeById = async (id: string, token: string): Promise<CustomResponse<ServiceType>> => {
        let serviceType: CustomResponse<ServiceType> = {} as CustomResponse<ServiceType>;
        await api.get(`TipoServicios/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            serviceType = response.data;
        }).catch(error => {
            serviceType = error;
        });
        return serviceType;
    }

    const createServiceType = async (serviceTypeRequest: ServiceTypeRequest, token: string): Promise<CustomResponse<ServiceType>> => {
        let serviceType: CustomResponse<ServiceType> = {} as CustomResponse<ServiceType>;
        await api.post('TipoServicios', serviceTypeRequest, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            serviceType = response.data;
        }).catch(error => {
            serviceType = error;
        });
        return serviceType;
    }

    const updateServiceType = async (serviceTypeRequest: ServiceType, token: string): Promise<CustomResponse<ServiceType>> => {
        let serviceType: CustomResponse<ServiceType> = {} as CustomResponse<ServiceType>;
        await api.put('TipoServicios', serviceTypeRequest, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            serviceType = response.data;
        }).catch(error => {
            serviceType = error;
        });
        return serviceType;
    }

    const deleteServiceType = async (id: string, token: string): Promise<CustomResponse<boolean>> => {
        let flat: CustomResponse<boolean> = {} as CustomResponse<boolean>;
        await api.delete(`TipoServicios/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            flat = response.data;
        }).catch(error => {
            flat = error;
        });
        return flat;
    }

    return {
        getAllServiceTypes,
        getServiceTypesByDescription,
        getServiceTypeById,
        createServiceType,
        updateServiceType,
        deleteServiceType
    }
}