import { Box, Button, Card, CardContent, Container, FormControlLabel, Grid, InputBase, Paper, Radio, RadioGroup, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { BillPay } from "../../../models/billPay";
import { BillPayService } from "../../../services/BillPayService";
import { getDataUser } from '../../../storage/userStorage';
import { useNavigate } from "react-router-dom";
import { SweetAlert } from "../../../components/SweetAlert";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DialogDetail from "./DialogDetail";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2e3192',
    color: 'white',
    fontSize: '1rem',
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.9rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const BillPays = () => {
    const navigation = useNavigate();
    const billPayService = BillPayService();
    const sweetAlert = SweetAlert();
    const [billPays, setBillPays] = useState<BillPay[]>([]);
    const [billPaysFilter, setBillPaysFilter] = useState<BillPay[]>([]);
    const [radioValue, setRadioValue] = useState<string>('');
    const [dateFilter, setDateFilter] = useState({
        fechaDesde: new Date(),
        fechaHasta: new Date()
    });
    const [showDateFilter, setShowDateFilter] = useState<boolean>(false);
    const [billpayDetail, setBillPayDetail] = useState<BillPay>({} as BillPay);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [payTotal, setPayTotal] = useState<number>(0);

    const actionBillPay = (id?: string) => {
        if (id === undefined)
            navigation('/cuenta-pagar/0/0');
        else
            navigation(`/cuenta-pagar/${id}/0`);
    }

    const deleteBillPay = async (id: string, userFullName: string) => {
        let result = await sweetAlert.showAlertWithButton(`la cuenta por pagar ${userFullName}`);
        if (result) {
            const response = await billPayService.deleteBillPay(id, getDataUser().token);
            if (!response.isSuccess) {
                sweetAlert.showAlert(response.message, response.isSuccess)
            }
            else {
                sweetAlert.showAlert('La cuenta por pagar se elimino correctamente', response.isSuccess);
                setBillPaysFilter(billPays.filter(x => x.id !== id));
            }
        }
    }

    const searchBillPays = (event: any) => {
        if (event.target.value !== '') {
            const newData = billPays.filter(function(item) {
                const itemDescripcion = item.descripcion.toUpperCase();
                const textData = event.target.value.toUpperCase();
                return itemDescripcion.indexOf(textData) > -1;
            });
            setBillPaysFilter(newData);
        } else {
            setBillPaysFilter(billPays);
        }
    }

    const calculateDaysBetweenDate = (fechaLimitePago: Date): number => {
        const actualDate = new Date();
        const fechaLimite = new Date(fechaLimitePago);
        let diff = fechaLimite.getTime() - actualDate.getTime();
        return Math.round(diff / (1000 * 60 * 60 * 24));
    }

    const showDialogDetail = (id: string) => {
        const data = billPays.find(x => x.id === id);
        setBillPayDetail(data !== undefined ? data : billpayDetail);
        setShowDetail(true);
    }

    const handleRadioChange = (event: any) => {
        setRadioValue(event.target.value);
        if (event.target.value === 'rangoFechas')
            setShowDateFilter(true);
    }

    const changeDate = (newValue: any, fecha: string) => {
        var date = new Date();
        date.setDate(newValue.$D);
        date.setMonth(newValue.$M)
        date.setFullYear(newValue.$y);
        if (fecha === 'desde')
            setDateFilter({...dateFilter, fechaDesde: date});
        else
            setDateFilter({...dateFilter, fechaHasta: date});
    }

    const resetFilter = () => {
        setRadioValue('');
        setBillPaysFilter(billPays);
        setDateFilter({...dateFilter, fechaDesde: new Date(), fechaHasta: new Date()});
        setShowDateFilter(false);
    }

    const applyFilter = () => {
        let data: BillPay[];
        switch (radioValue) {
            case 'todos':
                data = billPays;
                setBillPaysFilter(data);
                calculatePayTotal(data);
                break;
            case 'pagados':
                data = billPays.filter(x => x.estatus === 'Pagado');
                setBillPaysFilter(data);
                calculatePayTotal(data);
                break;
            case 'noPagados':
                data = billPays.filter(x => x.estatus !== 'Pagado');
                setBillPaysFilter(data);
                calculatePayTotal(data);
                break;
            case 'rangoFechas':
                data = billPays.filter(x => new Date(x.fechaLimitePago) >= new Date(dateFilter.fechaDesde)
                        && new Date(x.fechaLimitePago) <= new Date(dateFilter.fechaHasta));
                setBillPaysFilter(data);
                calculatePayTotal(data);
                break;
        }
    }

    const handleCloseModal = () => {
        setShowDetail(false);
    };

    const calculatePayTotal = (data: BillPay[]) => {
        let total: number = 0;
        data.forEach(element => {
            total += element.saldo;
        });
        setPayTotal(total);
    }

    useEffect(() => {
        const getBillPays = async () => {
            const data = await billPayService.getAllBillPays(getDataUser().token);
            if (data.isSuccess) {
                setBillPays(data.data);
                setBillPaysFilter(data.data);
                calculatePayTotal(data.data);
            }
        }
        getBillPays();
    }, [])

    return (
        <Container>
            <Typography variant="body2">Cuentas por pagar</Typography>
            <Button variant='contained'
                    color='primary'
                    sx={{
                        marginLeft: '80%'
                    }}
                    onClick={() => actionBillPay()}>
                Crear cuenta por pagar
            </Button>
            <Grid sx={{
                marginTop: '2%'
            }}>
                <Card variant='outlined'
                    sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)', marginBottom: '2rem' }}>
                    <CardContent>
                        <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={radioValue}
                            onChange={handleRadioChange} >
                            <Grid container>
                                <Grid item xs={12} sm={3}>
                                    <FormControlLabel value="todos" control={<Radio />} label="Todos" />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControlLabel value="pagados" control={<Radio />} label="Pagados" />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControlLabel value="noPagados" control={<Radio />} label="No pagados" />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControlLabel value="rangoFechas" control={<Radio />} label="Rango de fechas" />
                                </Grid>
                            </Grid>
                        </RadioGroup>
                        {showDateFilter ?
                            <Grid container
                                sx={{ marginTop: '1rem' }}>
                                <Grid item xs={12} sm={2}
                                    sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                                    Fecha desde
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <DatePicker slotProps={{ textField: { fullWidth: true } }}
                                        onChange={ (newValue) => changeDate(newValue, 'desde') }
                                        value={dayjs(dateFilter.fechaDesde)}
                                        format='DD/MM/YYYY' />
                                </Grid>
                                <Grid item xs={12} sm={2}
                                    sx={{ paddingTop: '1rem', paddingLeft: '3rem' }}>
                                    Fecha hasta
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <DatePicker slotProps={{ textField: { fullWidth: true } }}
                                        onChange={ (newValue) => changeDate(newValue, 'hasta') }
                                        value={dayjs(dateFilter.fechaHasta)}
                                        format='DD/MM/YYYY' />
                                </Grid>
                            </Grid> : ''
                        }
                    </CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginLeft: '1rem', marginRight: '1rem', marginBottom: '1rem' }}>
                        <Button color='inherit'
                            variant='outlined'
                            disabled={radioValue === ''}
                            onClick={resetFilter}
                            sx={{ mr: 1 }}>
                            Limpiar filtro
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={applyFilter}
                            disabled={radioValue === ''}
                            variant='contained'
                            color='primary'>
                            Filtrar
                        </Button>
                    </Box>
                </Card>
                <Paper component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto', marginBottom: '2rem' }} >
                    <InputBase sx={{ ml: 1, flex: 1 }}
                        placeholder="Buscar por descripcion"
                        name='inputSearch'
                        onChange={ (event) => searchBillPays(event) }/>
                </Paper>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%', minHeight: '20%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Descripcion</StyledTableCell>
                                <StyledTableCell width='15%'>Fecha Limite de Pago</StyledTableCell>
                                <StyledTableCell width='15%'>Estatus</StyledTableCell>
                                <StyledTableCell width='15%'>Dias a Vencer</StyledTableCell>
                                <StyledTableCell width='15%'>Vencido</StyledTableCell>
                                <StyledTableCell width='20%' align='right'></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {billPaysFilter?.map((billPay) => (
                                <StyledTableRow key={billPay.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {billPay.descripcion}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>{new Date(billPay.fechaLimitePago).toLocaleDateString('en-GB')}</StyledTableCell>
                                    <StyledTableCell align='center'>{billPay.estatus}</StyledTableCell>
                                    <StyledTableCell align='center'>{calculateDaysBetweenDate(billPay.fechaLimitePago)}</StyledTableCell>
                                    <StyledTableCell align='center'>
                                        {billPay.estatus === 'Pagado' ?
                                            <CreditScoreIcon sx={{ color: '#2e3192' }} /> :
                                        billPay.estatus === 'Abonado' ?
                                            <CreditCardIcon sx={{ color: '#fbcf33' }} /> :
                                        calculateDaysBetweenDate(billPay.fechaLimitePago) < 0
                                        && billPay.estatus === 'Pendiente' ?
                                            <CreditCardOffIcon sx={{ color: '#ea0606' }} /> :
                                            <CreditCardIcon />}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        <Grid container>
                                            <Grid item xs={12} sm={6}>
                                                <Button onClick={() => actionBillPay(billPay.id)}>
                                                    <ModeEditIcon />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {billPay.estatus !== 'Pendiente' ?
                                                    <Button onClick={() => showDialogDetail(billPay.id)}>
                                                        <SummarizeIcon />
                                                    </Button> :
                                                    <Button onClick={() => deleteBillPay(billPay.id, `${billPay.descripcion}`)}>
                                                        <DeleteIcon />
                                                    </Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Typography variant='body1' align='right' sx={{ marginTop: '1rem', marginRight: '1rem' }}>
                            Total a pagar: <strong>${new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(payTotal)}</strong>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <DialogDetail data={billpayDetail} open={showDetail} onClose={handleCloseModal} />
        </Container>
    )
}
