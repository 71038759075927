import { Route, Routes, Navigate } from 'react-router-dom';
import { BillPays } from '../views/app/billPay/BillPay';
import { BillPayActions } from '../views/app/billPay/BillPayActions';
import { Clients } from '../views/app/client/Client';
import { ClientActions } from '../views/app/client/ClientActions';
import { DailyCashBalanceView } from '../views/app/dailyCashBalance/DailyCashBalance';
import { ExpensePurchaseControlDetail } from '../views/app/expensePurchaseControl/ExpensePurchaseControl';
import { ExpensePurchaseControlAction } from '../views/app/expensePurchaseControl/ExpensePurchaseControlAction';
import { Home } from '../views/app/Home';
import { RateDays } from '../views/app/rateDay/RateDay';
import { ServiceTypes } from '../views/app/serviceType/ServiceType';
import { ServiceTypeActions } from '../views/app/serviceType/ServiceTypeActions';
import { Suppliers } from '../views/app/supplier/Supplier';
import { SupplierActions } from '../views/app/supplier/SupplierActions';
import { FollowUp } from '../views/app/tracking/FollowUp';
import { TrackingAction } from '../views/app/tracking/Tracking';
import { TrackingHistory } from '../views/app/tracking/TrackingHistory';
import { Users } from '../views/app/user/User';
import { UserActions } from '../views/app/user/UserActions';

export const ContentRouter = () => {
    return (
        <Routes>
            <Route path='/*' element={ <Home /> } />

            <Route path='/tracking' element={ <TrackingAction /> } />
            <Route path='/historial-envios' element={ <TrackingHistory /> } />
            <Route path='/seguimiento' element={ <FollowUp /> } />

            <Route path='/cuadre-caja' element={ <DailyCashBalanceView /> } />

            <Route path='/cuentas-pagar' element={ <BillPays /> } />
            <Route path='/cuenta-pagar/:id/:idDetail' element={ <BillPayActions /> } />

            <Route path='/control-gastos' element={ <ExpensePurchaseControlDetail /> } />
            <Route path='/control-gastos/:id' element={ <ExpensePurchaseControlAction /> } />

            <Route path='/administracion/usuarios' element={ <Users /> } />
            <Route path='/administracion/usuario/:id' element={ <UserActions /> } />
            <Route path='/administracion/clientes' element={ <Clients /> } />
            <Route path='/administracion/cliente/:id' element={ <ClientActions /> } />
            <Route path='/administracion/proveedores' element={ <Suppliers /> } />
            <Route path='/administracion/proveedor/:id' element={ <SupplierActions /> } />
            <Route path='/administracion/tipo-servicios' element={ <ServiceTypes /> } />
            <Route path='/administracion/tipo-servicio/:id' element={ <ServiceTypeActions /> } />
            <Route path='/administracion/tasa-dia' element={ <RateDays /> } />

            <Route path='/*' element={ <Navigate to='/home' /> } />
        </Routes>
    )
}
