import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import BasicLayout from './components/BasicLayout';
import { Alert, Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import { useNavigate } from 'react-router-dom';
import { Login } from '../../models/user/Login';
import { UserService } from '../../services/UserService';
import { User } from '../../models/user';
import { CustomResponse } from '../../models';
import { setDataUser } from '../../storage/userStorage';

function Basic() {
  const navigation = useNavigate();
  const userService = UserService();
  var md5 = require('md5');
  const [hasError, setHasError] = useState({
      usuario: false,
      password: false
  });
  const [loginError, setLoginError] = useState({
    error: false,
    message: ''
  })
  const [credential, setCredential] = useState<Login>({
      nombreUsuario: '',
      contrasena: ''
  });
  const [isValidation, setIsValidation] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const changeInput = (event: any) => {
    const {name, value} = event.target;
    switch (name) {
      case 'usuario':
          setCredential({ nombreUsuario: value, contrasena: credential.contrasena });
          break;
      case 'password':
          setCredential({ nombreUsuario: credential.nombreUsuario, contrasena: md5(value) });
          break;
    }
  }

  useEffect(() => {
    if (localStorage.length !== 0)
      navigation('/');
  }, [])

  const DoLogin = async () => {
    setLoginError({ error: false, message: '' })
    if (credential.nombreUsuario === '' || credential.contrasena === '') {
        setHasError({
          usuario: credential.nombreUsuario === '',
          password: credential.contrasena === '',
        });
        return;
    }
    setIsValidation(true);
    setHasError({ usuario: false, password: false });
    const user: CustomResponse<User> = await userService.doLogin(credential);
    setIsValidation(false);
    if (!user.isSuccess) {
      setLoginError({ error: true, message: user.message });
      return;
    }
    setDataUser(user.data);
    navigation('/');
  }

  return (
    <BasicLayout
      title='Galivica Express'
      description=''
    >
      <Card>
        <Box p={3} mb={1} textAlign='center'>
          <Typography variant='h5' fontWeight='medium'>
            Iniciar Sesion
          </Typography>
        </Box>
        <Box p={3}>
          <Box component='form' role='form'>
            <Box mb={2}>
              <TextField type='text'
                        placeholder='Usuario'
                        name='usuario'
                        fullWidth
                        required
                        variant='outlined'
                        onChange={ changeInput }
                        disabled={ isValidation }
                        error={ hasError.usuario }
                        helperText={ hasError.usuario ? 'Debe ingresar un usuario' : ''}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }} />
            </Box>
            <Box mb={2}>
              <TextField type='password'
                        placeholder='Contraseña'
                        name='password'
                        fullWidth
                        required
                        variant='outlined'
                        onChange={changeInput}
                        disabled={ isValidation }
                        error={ hasError.password }
                        helperText={ hasError.password ? 'Debe ingresar una contraseña' : ''}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <KeyIcon />
                            </InputAdornment>
                          ),
                        }} />
            </Box>
            <Box display='flex' alignItems='center'>
              <Switch checked={ rememberMe } onChange={ handleSetRememberMe } />
              <Typography
                variant='button'
                fontWeight='regular'
                onClick={ handleSetRememberMe }
                sx={{ cursor: 'pointer', userSelect: 'none' }}
              >
                &nbsp;&nbsp;Recordarme
              </Typography>
            </Box>
            <Box mt={4} mb={1}>
              <Button variant='contained'
                      color='primary'
                      fullWidth
                      disabled={ isValidation }
                      onClick={ DoLogin }>
                Ingresar
              </Button>
            </Box>
          </Box>
        </Box>
      </Card>
      {
        loginError.error ?
        <Alert variant='outlined' severity="error">{loginError.message}</Alert>
        : ''
      }
    </BasicLayout>
  );
}

export default Basic;
