import { Box, Button, Card, CardActions, CardContent, Container, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Client } from '../../../models/client';
import { ClientService } from '../../../services/ClientService';
import { getDataUser } from '../../../storage/userStorage';
import { SweetAlert } from "../../../components/SweetAlert";

export const ClientActions = () => {
    const navigation = useNavigate();
    const clientService = ClientService();
    const sweetAlert = SweetAlert();
    const [client, setClient] = useState<Client>({} as Client);
    const {id} = useParams();
    const [hasError, setHasError] = useState({
        nombre: false,
        apellido: false,
        tipoDocumento: false,
        numeroDocumento: false,
        correoFormato: false,
        direccion: false,
        ciudad: false,
        pais: false,
        telefono: false,
        observacion: false
    });
    const [isValidation, setIsValidation] = useState(false);

    const changeInput = (event: any) => {
      const {name, value} = event.target;
      const regex = /^[0-9]*$/;
      if (name === 'numeroDocumento' && !regex.test(value)) {
        setClient({ ...client, [name]: client.numeroDocumento });
        sweetAlert.showAlert('El campo Nro. Documento solo acepta valores numericos', false);
        return;
      }
      if (name === 'telefono' && !regex.test(value)) {
        setClient({ ...client, [name]: client.telefono });
        sweetAlert.showAlert('El campo Telefono solo acepta valores numericos', false);
        return;
      }
      setClient({ ...client, [name]: value });
    }

    const validateField = async () => {
        const formatValidate: boolean = false;
        if (client.correo !== undefined) validateFormatEmail(client.correo);
        if (client.nombre === undefined || client.apellido === undefined ||
            client.numeroDocumento === undefined || client.telefono === undefined
            || client.direccion === undefined || client.tipoDocumento === undefined
            || client.ciudad === undefined || client.pais === undefined
            || client.observacion === undefined || formatValidate) {
            setHasError({
                nombre: client.nombre === undefined,
                apellido: client.apellido === undefined,
                tipoDocumento: client.tipoDocumento === undefined,
                numeroDocumento: client.numeroDocumento === undefined,
                correoFormato: formatValidate,
                telefono: client.telefono === undefined,
                direccion: client.direccion === undefined,
                ciudad: client.ciudad === undefined,
                pais: client.ciudad === undefined,
                observacion: client.observacion === undefined
            });
            return true;
        }
        return false;
    }

    const validateFormatEmail = (correo: string) => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return !regex.test(correo);
    }

    const actionClient = async () => {
        setIsValidation(true);
        if (await validateField()) {
            setIsValidation(false);
            return;
        }
        const response = id !== '0' ? await clientService.updateClient(client, getDataUser().token)
                        : await clientService.createClient(client, getDataUser().token);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess);
            setIsValidation(false);
        }
        else {
            sweetAlert.showAlert(id !== '0' ? 'El cliente se modifico correctamente' : 'El cliente se creo correctamente', response.isSuccess);
            navigation('/administracion/clientes');
            setIsValidation(false);
        }
    }

    useEffect(() => {
        const getClient = async (id: any) => {
            const data = await clientService.getClientById(id, getDataUser().token);
            setClient(data.data);
        }
        if (id !== '0') getClient(id);
    }, [])

    return (
        <Container>
            <Typography variant="body2">Administracion/{id !== '0' ? 'Modificar Cliente' : 'Crear Cliente'}</Typography>
            <Grid sx={{
                marginTop: '5%'
            }}>
                <Typography variant='h3'>Datos del cliente</Typography>
                <Divider />
                <Box p={3}>
                    <Box mt={1.625}>
                        <Card variant='outlined'
                            sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)' }}>
                            <CardContent>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Nombre'
                                            name='nombre'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={client.nombre}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.nombre }
                                            helperText={ hasError.nombre ? 'Debe ingresar un nombre' : ''} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Apellido'
                                            name='apellido'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={client.apellido}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.apellido }
                                            helperText={ hasError.apellido ? 'Debe ingresar un apellido' : ''} />
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-helper-label">Tipo Documento</InputLabel>
                                            <Select value={client.tipoDocumento}
                                                label="Tipo Documento"
                                                name='tipoDocumento'
                                                onChange={ changeInput }
                                                error={ hasError.tipoDocumento }>
                                                    <MenuItem value='DNI'>DNI</MenuItem>
                                                    <MenuItem value='CPP'>CPP</MenuItem>
                                                    <MenuItem value='PTP'>PTP</MenuItem>
                                                    <MenuItem value='CE'>CE</MenuItem>
                                                    <MenuItem value='PAS'>PAS</MenuItem>
                                                    <MenuItem value='VE'>VE</MenuItem>
                                                    <MenuItem value='RUC'>RUC</MenuItem>
                                                    <MenuItem value='RIF'>RIF</MenuItem>
                                            </Select>
                                            {hasError.tipoDocumento ? <FormHelperText error={true}>Debe seleccionar un tipo de documento</FormHelperText> : ''}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Numero documento'
                                            name='numeroDocumento'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={client.numeroDocumento}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.numeroDocumento }
                                            helperText={ hasError.numeroDocumento ? 'Debe ingresar un numero de documento' : ''} />
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='email'
                                            placeholder='Correo electronico'
                                            name='correo'
                                            fullWidth
                                            variant='outlined'
                                            value={client.correo}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.correoFormato }
                                            helperText={ hasError.correoFormato ? 'No se ingreso un correo valido' : ''} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Telefono'
                                            name='telefono'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={client.telefono}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.telefono }
                                            helperText={ hasError.telefono ? 'Debe ingresar un telefono' : ''} />
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField type='text'
                                            placeholder='Direccion'
                                            name='direccion'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={client.direccion}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.direccion }
                                            helperText={ hasError.direccion ? 'Debe ingresar una direccion' : ''} />
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Ciudad'
                                            name='ciudad'
                                            fullWidth
                                            variant='outlined'
                                            value={client.ciudad}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.ciudad }
                                            helperText={ hasError.ciudad ? 'Debe ingresar una ciudad' : ''} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Pais'
                                            name='pais'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={client.pais}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.pais }
                                            helperText={ hasError.pais ? 'Debe ingresar un pais' : ''} />
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField type='text'
                                            placeholder='Observacion'
                                            name='observacion'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={client.observacion}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.observacion }
                                            helperText={ hasError.observacion ? 'Debe ingresar una observacion' : ''} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button variant='contained'
                                        color='primary'
                                        sx={{
                                            marginLeft: '75%',
                                            width: '25%'
                                        }}
                                        disabled={ isValidation }
                                        onClick={ actionClient }>
                                    {id !== '0' ? 'Modificar Cliente' : 'Crear Cliente'}
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Box>
            </Grid>
        </Container>
    )
}
