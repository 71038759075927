import { Button, Container, Grid, InputBase, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from "react";
import { Supplier } from "../../../models/supplier";
import { SupplierService } from '../../../services/SupplierService';
import { getDataUser } from '../../../storage/userStorage';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SweetAlert } from "../../../components/SweetAlert";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2e3192',
    color: 'white',
    fontSize: '1rem',
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.9rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const Suppliers = () => {
    const navigation = useNavigate();
    const supplierService = SupplierService();
    const sweetAlert = SweetAlert();
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const [suppliersFilter, setSuppliersFilter] = useState<Supplier[]>([]);

    const actionSupplier = (id?: string) => {
        if (id === undefined)
            navigation('/administracion/proveedor/0');
        else
            navigation(`/administracion/proveedor/${id}`);
    }

    const deleteSupplier = async (id: string, supplier: string) => {
        let result = await sweetAlert.showAlertWithButton(`el proveedor ${supplier}`);
        if (result) {
            const response = await supplierService.deleteSupplier(id, getDataUser().token);
            if (!response.isSuccess) {
                sweetAlert.showAlert(response.message, response.isSuccess)
            }
            else {
                sweetAlert.showAlert('El proveedor se elimino correctamente', response.isSuccess);
                setSuppliersFilter(suppliers.filter(x => x.id !== id));
            }
        }
    }

    const searchSuppliers = (event: any) => {
        if (event.target.value !== '') {
            const newData = suppliers.filter(function(item) {
                const itemNombre = item.nombre.toUpperCase();
                const textData = event.target.value.toUpperCase();
                return itemNombre.indexOf(textData) > -1;
            });
            setSuppliersFilter(newData);
        } else {
            setSuppliersFilter(suppliers);
        }
    }

    useEffect(() => {
        const getSuppliers = async () => {
            const data = await supplierService.getAllSuppliers(getDataUser().token);
            setSuppliers(data.data);
            setSuppliersFilter(data.data);
        }
        getSuppliers();
    }, [])

    return (
        <Container>
            <Typography variant="body2">Administracion/Proveedores</Typography>
            <Button variant='contained'
                    color='primary'
                    sx={{
                        marginLeft: '80%'
                    }}
                    onClick={() => actionSupplier()}>
                Crear proveedor
            </Button>
            <Grid sx={{
                marginTop: '5%'
            }}>
                <Paper component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto', marginBottom: '2rem' }} >
                    <InputBase sx={{ ml: 1, flex: 1 }}
                        placeholder='Buscar por nombre'
                        name='inputSearch'
                        onChange={ (event) => searchSuppliers(event) }/>
                </Paper>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%', minHeight: '20%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Nombre</StyledTableCell>
                                <StyledTableCell>Direccion</StyledTableCell>
                                <StyledTableCell width='15%'>Telefono</StyledTableCell>
                                <StyledTableCell width='15%'>Correo</StyledTableCell>
                                <StyledTableCell width='15%'>Tipo Documento</StyledTableCell>
                                <StyledTableCell width='15%'>RUC/RIF</StyledTableCell>
                                <StyledTableCell width='15%' align='right'></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {suppliersFilter?.map((supplier) => (
                                <StyledTableRow key={supplier.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {supplier.nombre}
                                    </StyledTableCell>
                                    <StyledTableCell>{supplier.direccion}</StyledTableCell>
                                    <StyledTableCell>{supplier.telefono}</StyledTableCell>
                                    <StyledTableCell>{supplier.correo}</StyledTableCell>
                                    <StyledTableCell>{supplier.tipoDocumento}</StyledTableCell>
                                    <StyledTableCell>{supplier.ruC_RIF}</StyledTableCell>
                                    <StyledTableCell>
                                        <Button onClick={() => actionSupplier(supplier.id)}>
                                            <ModeEditIcon />
                                        </Button>
                                        <Button onClick={() => deleteSupplier(supplier.id, supplier.nombre)}>
                                            <DeleteIcon />
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Container>
    )
}
