import { Button, Card, CardActions, CardContent, Container, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useState } from 'react';
import { Product } from '../../../models/tracking';
import DeleteIcon from '@mui/icons-material/Delete';
import { SweetAlert } from "../../../components/SweetAlert";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2e3192',
    color: 'white',
    fontSize: '1rem',
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.9rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StepProducts = ({data, setData, rateDay, handleStep}: any) => {
    const [product, setProduct] = useState<Product>({} as Product);
    const [isValidation, setIsValidation] = useState(false);
    const [hasError, setHasError] = useState({
        descripcion: false,
        cantidad: false,
        tipoProducto: false,
        totalSoles: false,
        totalDolares: false
    });
    const sweetAlert = SweetAlert();

    const changeInput = (event: any) => {
        const {name, value} = event.target;
        const regex = /^[0-9\,]*$/;
        if (name === 'cantidad' && !regex.test(value)) {
          setProduct({ ...product, [name]: product.cantidad });
          sweetAlert.showAlert('El campo Cantidad solo acepta valores numericos', false);
          return;
        }
        if (name === 'totalSoles' && !regex.test(value)) {
          setProduct({ ...product, [name]: product.totalSoles });
          sweetAlert.showAlert('El campo Total en Soles solo acepta valores numericos', false);
          return;
        }
        setProduct({ ...product, [name]: value });
    }

    const validateField = async () => {
        debugger;
        if (product.descripcion === undefined || product.cantidad === undefined ||
            product.tipoProducto === undefined || product.totalSoles === undefined ||
            product.totalDolares === undefined) {
            setHasError({
                descripcion: product.descripcion === undefined,
                cantidad: product.cantidad === undefined,
                tipoProducto: product.tipoProducto === undefined,
                totalSoles: product.totalSoles === undefined,
                totalDolares: product.totalDolares === undefined
            });
            return true;
        }
        return false;
    }

    const addProduct = async () => {
        setIsValidation(true);
        if (await validateField()) {
            setIsValidation(false);
            return;
        }
        product.cantidad = parseFloat(product.cantidad.toString().replace('""', ''));
        getResult(product.totalSoles, product.cantidad, 'Soles');
        getResult(product.totalDolares, product.cantidad, 'Dolares');
        setData([...data, product]);
        setIsValidation(false);
        handleStep(false);
    }

    const deleteProduct = async (product: Product) => {
        let result = await sweetAlert.showAlertWithButton(`el producto ${product.descripcion}`);
        if (result) {
            setData(data.filter((x: Product) => x.descripcion !== product.descripcion));
            handleStep(data.length === 1);
        }
    }

    const calculateDollarValue = (e: any) => {
        const {name, value} = e.target;
        const totalSoles = parseFloat(value.toString().replace(',', '.'));
        product.totalSoles = totalSoles;
        const result = totalSoles / rateDay.tasa;
        setProduct({...product, totalDolares: result});
    }

    const getResult = (valor: number, cantidad: number, nombre: string) => {
        const valorFormat = parseFloat(valor.toString().replace(',', '.'));
        const result = valorFormat / cantidad;
        switch (nombre) {
            case 'Soles':
                product.totalSoles = parseFloat(product.totalSoles.toString().replace(',', '.'));
                product.valorSoles = parseFloat(result.toString().replace(',', '.'));
                setData([...data, product])
                break;
            case 'Dolares':
                product.totalDolares = parseFloat(product.totalDolares.toString().replace(',', '.'));
                product.valorDolares = parseFloat(result.toString().replace(',', '.'));
                setData([...data, product])
                break;
        }
        return result;
    }

    return (
        <Container>
            <Grid>
                <Card variant='outlined'>
                    <CardContent>
                        <Grid container
                            spacing={3}
                            sx={{ marginBottom: '2%' }}>
                            <Grid item xs={12} sm={12}>
                                <TextField type='text'
                                    placeholder='Descripcion'
                                    name='descripcion'
                                    value={product.descripcion}
                                    fullWidth
                                    required
                                    variant='outlined'
                                    onChange={ changeInput }
                                    disabled={ isValidation }
                                    error={ hasError.descripcion }
                                    helperText={ hasError.descripcion ? 'Debe ingresar una descripcion' : ''} />
                            </Grid>
                        </Grid>
                        <Grid container
                            spacing={3}
                            sx={{ marginBottom: '2%' }}>
                            <Grid item xs={12} sm={6}>
                                <TextField type='text'
                                    placeholder='Cantidad'
                                    name='cantidad'
                                    value={product.cantidad}
                                    fullWidth
                                    required
                                    variant='outlined'
                                    onChange={ changeInput }
                                    disabled={ isValidation }
                                    error={ hasError.cantidad }
                                    helperText={ hasError.cantidad ? 'Debe ingresar una cantidad' : ''} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Tipo Producto</InputLabel>
                                    <Select label="Tipo Producto"
                                        name='tipoProducto'
                                        value={product.tipoProducto}
                                        onChange={ changeInput }
                                        error={ hasError.tipoProducto }>
                                            <MenuItem value='Unidades'>Unidades</MenuItem>
                                            <MenuItem value='Pares'>Pares</MenuItem>
                                            <MenuItem value='Paquetes'>Paquetes</MenuItem>
                                            <MenuItem value='Juegos'>Juegos</MenuItem>
                                    </Select>
                                    {hasError.tipoProducto ? <FormHelperText error={true}>Debe seleccionar un tipo de producto</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container
                            spacing={3}
                            sx={{ marginBottom: '2%' }}>
                            <Grid item xs={12} sm={6}>
                                <TextField type='text'
                                    placeholder='Total en Soles'
                                    name='totalSoles'
                                    value={product.totalSoles !== undefined ? product.totalSoles.toString().replace('.', ',') : 0}
                                    fullWidth
                                    required
                                    onBlur={ (e) => calculateDollarValue(e) }
                                    variant='outlined'
                                    onChange={ changeInput }
                                    disabled={ isValidation }
                                    error={ hasError.totalSoles }
                                    helperText={ hasError.totalSoles ? 'Debe ingresar un total en soles' : ''} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField type='text'
                                    placeholder='Total en Dolares'
                                    name='totalDolares'
                                    value={product.totalDolares !== undefined ? product.totalDolares.toString().replace('.', ',') : 0}
                                    fullWidth
                                    required
                                    variant='outlined'
                                    disabled={ true } />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button variant='contained'
                                color='primary'
                                sx={{
                                    marginLeft: '75%',
                                    width: '25%'
                                }}
                                disabled={ isValidation }
                                onClick={ addProduct }>
                            Agregar
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid sx={{
                marginTop: '5%'
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%', minHeight: '20%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Descripcion</StyledTableCell>
                                <StyledTableCell width='10%'>Cantidad</StyledTableCell>
                                <StyledTableCell width='10%'>Tipo Producto</StyledTableCell>
                                <StyledTableCell width='10%' align='right'>Valor Unitario en Soles</StyledTableCell>
                                <StyledTableCell width='10%' align='right'>Total en Soles</StyledTableCell>
                                <StyledTableCell width='10%' align='right'>Valor Unitario en Dolares</StyledTableCell>
                                <StyledTableCell width='10%' align='right'>Total en Dolares</StyledTableCell>
                                <StyledTableCell width='10%'></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((item: Product) => (
                                <StyledTableRow key={item.descripcion}>
                                    <StyledTableCell component="th" scope="row">
                                        {item.descripcion}
                                    </StyledTableCell>
                                    <StyledTableCell>{item.cantidad}</StyledTableCell>
                                    <StyledTableCell>{item.tipoProducto}</StyledTableCell>
                                    <StyledTableCell>
                                        {new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(item.valorSoles.toString().replace(',', '.')))}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(item.totalSoles.toString().replace(',', '.')))}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(item.valorDolares.toString().replace(',', '.')))}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(item.totalDolares.toString().replace(',', '.')))}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Button onClick={() => deleteProduct(item)}>
                                            <DeleteIcon />
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Container>
    )
}
