import { Box, Button, Card, CardActions, CardContent, Container, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Supplier } from '../../../models/supplier';
import { SupplierService } from '../../../services/SupplierService';
import { getDataUser } from '../../../storage/userStorage';
import { SweetAlert } from "../../../components/SweetAlert";

export const SupplierActions = () => {
    const navigation = useNavigate();
    const supplierService = SupplierService();
    const sweetAlert = SweetAlert();
    const [supplier, setSupplier] = useState<Supplier>({} as Supplier);
    const {id} = useParams();
    const [hasError, setHasError] = useState({
        nombre: false,
        direccion: false,
        telefono: false,
        correo: false,
        tipoDocumento: false,
        ruc_rif: false
    });
    const [isValidation, setIsValidation] = useState(false);

    const changeInput = (event: any) => {
      const {name, value} = event.target;
      const regex = /^[0-9]*$/;
      if (name === 'ruC_RIF' && !regex.test(value)) {
        setSupplier({ ...supplier, [name]: supplier.ruC_RIF });
        sweetAlert.showAlert('El campo RUC/RIF solo acepta valores numericos', false);
        return;
      }
      if (name === 'telefono' && !regex.test(value)) {
        setSupplier({ ...supplier, [name]: supplier.telefono });
        sweetAlert.showAlert('El campo Telefono solo acepta valores numericos', false);
        return;
      }
      setSupplier({ ...supplier, [name]: value });
    }

    const validateField = async () => {
        if (supplier.nombre === undefined || supplier.correo === undefined ||
            supplier.telefono === undefined || supplier.direccion === undefined ||
            supplier.tipoDocumento === undefined || supplier.ruC_RIF === undefined) {
            setHasError({
                nombre: supplier.nombre === undefined,
                telefono: supplier.telefono === undefined,
                direccion: supplier.direccion === undefined,
                correo: supplier.correo === undefined,
                tipoDocumento: supplier.tipoDocumento === undefined,
                ruc_rif: supplier.ruC_RIF === undefined
            });
            return true;
        }
        return false;
    }

    const actionClient = async () => {
        setIsValidation(true);
        if (await validateField()) {
            setIsValidation(false);
            return;
        }
        const response = id !== '0' ? await supplierService.updateSupplier(supplier, getDataUser().token)
                        : await supplierService.createSupplier(supplier, getDataUser().token);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess)
            setIsValidation(false);
        }
        else {
            sweetAlert.showAlert(id !== '0' ? 'El proveedor se modifico correctamente' : 'El proveedor se creo correctamente', response.isSuccess);
            navigation('/administracion/proveedores');
            setIsValidation(false);
        }
    }

    useEffect(() => {
        const getSupplier = async (id: any) => {
            const data = await supplierService.getSupplierById(id, getDataUser().token);
            setSupplier(data.data);
        }
        if (id !== '0') getSupplier(id);
    }, [])

    return (
        <Container>
            <Typography variant="body2">Administracion/{id !== '0' ? 'Modificar Proveedor' : 'Crear Proveedor'}</Typography>
            <Grid sx={{
                marginTop: '5%'
            }}>
                <Typography variant='h3'>Datos del proveedor</Typography>
                <Divider />
                <Box p={3}>
                    <Box mt={1.625}>
                        <Card variant='outlined'
                            sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)' }}>
                            <CardContent>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Nombre'
                                            name='nombre'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={supplier.nombre}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.nombre }
                                            helperText={ hasError.nombre ? 'Debe ingresar un nombre' : ''} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='Telefono'
                                            name='telefono'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={supplier.telefono}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.telefono }
                                            helperText={ hasError.telefono ? 'Debe ingresar un telefono' : ''} />
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-helper-label">Tipo Documento</InputLabel>
                                            <Select value={supplier.tipoDocumento}
                                                label="Tipo Documento"
                                                name='tipoDocumento'
                                                onChange={ changeInput }
                                                error={ hasError.tipoDocumento }>
                                                    <MenuItem value='RUC'>RUC</MenuItem>
                                                    <MenuItem value='RIF'>RIF</MenuItem>
                                                    <MenuItem value='DI'>Documento de Identidad</MenuItem>
                                            </Select>
                                            {hasError.tipoDocumento ? <FormHelperText error={true}>Debe seleccionar un tipo de documento</FormHelperText> : ''}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type='text'
                                            placeholder='RUC/RIF'
                                            name='ruC_RIF'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={supplier.ruC_RIF}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.ruc_rif }
                                            helperText={ hasError.ruc_rif ? 'Debe ingresar un RUC/RIF' : ''} />
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField type='email'
                                            placeholder='Correo electronico'
                                            name='correo'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={supplier.correo}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.correo }
                                            helperText={ hasError.correo ? 'Debe ingresar un correo electronico' : ''} />
                                    </Grid>
                                </Grid>
                                <Grid container
                                    spacing={3}
                                    sx={{ marginBottom: '2%' }}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField type='text'
                                            placeholder='Direccion'
                                            name='direccion'
                                            fullWidth
                                            required
                                            variant='outlined'
                                            value={supplier.direccion}
                                            onChange={ changeInput }
                                            disabled={ isValidation }
                                            error={ hasError.direccion }
                                            helperText={ hasError.direccion ? 'Debe ingresar una direccion' : ''} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button variant='contained'
                                        color='primary'
                                        sx={{
                                            marginLeft: '75%',
                                            width: '25%'
                                        }}
                                        disabled={ isValidation }
                                        onClick={ actionClient }>
                                    {id !== '0' ? 'Modificar Proveedor' : 'Crear Proveedor'}
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Box>
            </Grid>
        </Container>
    )
}
