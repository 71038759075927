import { User } from "../models/user";

export const setDataUser = (user: User) => {
    localStorage.setItem('Id', user.id);
    localStorage.setItem('Nombre', user.nombre);
    localStorage.setItem('Apellido', user.apellido);
    localStorage.setItem('NombreUsuario', user.nombreUsuario);
    localStorage.setItem('Contrasena', user.contrasena);
    localStorage.setItem('IdPerfil', user.idPerfil);
    localStorage.setItem('Perfil', user.perfil);
    localStorage.setItem('Token', user.token);
}

export const getDataUser = (): User => {
    const user: User = {
        id: localStorage.getItem('Id') || '',
        nombre: localStorage.getItem('Nombre') || '',
        apellido: localStorage.getItem('Apellido') || '',
        nombreUsuario: localStorage.getItem('NombreUsuario') || '',
        contrasena: localStorage.getItem('Contrasena') || '',
        idPerfil: localStorage.getItem('IdPerfil') || '',
        perfil: localStorage.getItem('Perfil') || '',
        token: localStorage.getItem('Token') || ''
    }
    return user;
}

export const removeDataUser = () => {
    localStorage.clear();
}