import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './router/AppRouter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function App() {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
        <AppRouter />
      </LocalizationProvider>
    </BrowserRouter>
  );
}
