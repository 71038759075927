import { CustomResponse } from '../models';
import { Supplier } from '../models/supplier';
import api from './ConfigService';

export const SupplierService = () => {
    const getAllSuppliers = async (token: string): Promise<CustomResponse<Supplier[]>> => {
        let suppliers: CustomResponse<Supplier[]> = {isSuccess: false, responseCode: 0, message: '', data: []};
        await api.get('Proveedores', { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            suppliers = response.data;
        }).catch(error => {
            suppliers = error;
        });
        return suppliers;
    }

    const getSupplierById = async (id: string, token: string): Promise<CustomResponse<Supplier>> => {
        let supplier: CustomResponse<Supplier> = {} as CustomResponse<Supplier>;
        await api.get(`Proveedores/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            supplier = response.data;
        }).catch(error => {
            supplier = error;
        });
        return supplier;
    }

    const createSupplier = async (supplierRequest: Supplier, token: string): Promise<CustomResponse<Supplier>> => {
        let supplier: CustomResponse<Supplier> = {} as CustomResponse<Supplier>;
        await api.post('Proveedores', supplierRequest, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            supplier = response.data;
        }).catch(error => {
            supplier = error;
        });
        return supplier;
    }

    const updateSupplier = async (supplierRequest: Supplier, token: string): Promise<CustomResponse<Supplier>> => {
        let supplier: CustomResponse<Supplier> = {} as CustomResponse<Supplier>;
        await api.put('Proveedores', supplierRequest, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            supplier = response.data;
        }).catch(error => {
            supplier = error;
        });
        return supplier;
    }

    const deleteSupplier = async (id: string, token: string): Promise<CustomResponse<boolean>> => {
        let flat: CustomResponse<boolean> = {} as CustomResponse<boolean>;
        await api.delete(`Proveedores/${id}`, { headers: {'Authorization': `Bearer ${token}`} })
        .then(response => {
            flat = response.data;
        }).catch(error => {
            flat = error;
        });
        return flat;
    }

    return {
        getAllSuppliers,
        getSupplierById,
        createSupplier,
        updateSupplier,
        deleteSupplier
    }
}